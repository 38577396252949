import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

function Dashboard() {
	const [selectedYear, setSelectedYear] = useState("2025");
	const [financeData, setFinanceData] = useState([]);
	const [showFilterModal, setShowFilterModal] = useState(false);
	const [showAddPesananModal, setShowAddPesananModal] = useState(false);
	const [pesananList, setPesananList] = useState([]);
	const [products, setProducts] = useState([]);
	const [formData, setFormData] = useState({
		Invoice_ID: "",
		Tanggal: Date.now(),
		Nama_Toko: "",
		Kredit: 0,
		Debit: 0,
		Untung: 0,
		status: "Diproses",
		Catatan: "Tidak ada Catatan",
		Producttoko: "",
		Nama: "data tidak tersedia",
		No_Hp: 0,
		Kota: "data tidak tersedia",
		Provinsi: "data tidak tersedia",
		Alamat: "data tidak tersedia",
		PJ: "",
	});
	const [financeSummary, setFinanceSummary] = useState({
		total_danatertahan: 0,
		total_danacair: 0,
		ratarata_total_keuntungan: 0,
	});

	useEffect(() => {
		const fetchFinanceSummary = async () => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/api/finance-summary`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error("Failed to fetch finance summary");
				}

				const data = await response.json();
				setFinanceSummary(data);
			} catch (error) {
				console.error("Error fetching finance summary:", error);
			}
		};

		fetchFinanceSummary();
	}, []);

	useEffect(() => {
		const fetchPesananData = async () => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/pesanan`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setPesananList(data);
			} catch (error) {
				console.error("Failed to fetch pesanan:", error);
			}
		};

		const fetchProducts = async () => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/producttoko`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error("Failed to fetch products");
				}

				const data = await response.json();
				setProducts(data);
			} catch (error) {
				console.error("Error fetching products:", error);
			}
		};

		fetchPesananData();
		fetchProducts();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		let updatedFormData = { ...formData, [name]: value };

		if (name === "Kredit" || name === "Debit") {
			const kredit =
				name === "Kredit"
					? parseFloat(value) || 0
					: parseFloat(updatedFormData.Kredit) || 0;
			const debit =
				name === "Debit"
					? parseFloat(value) || 0
					: parseFloat(updatedFormData.Debit) || 0;
			updatedFormData.Untung = debit - kredit;
		}

		setFormData(updatedFormData);
	};

	const handleCalculateFinance = async (date) => {
		const currentYear = new Date(date).getFullYear();
		const currentMonth = new Date(date).getMonth() + 1;

		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/api/calculate-finance/${currentYear}/${currentMonth}`,
				{
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to calculate finance");
			}

			console.log(`Finance data updated for ${currentYear}-${currentMonth}`);
		} catch (error) {
			console.error("Error calculating finance:", error);
		}
	};

	const handleSavePesanan = async () => {
		try {
			const token = localStorage.getItem("token");
			const method = formData.ID ? "PUT" : "POST";
			const url = formData.ID
				? `${process.env.REACT_APP_API_BASE_URL}/pesanan/${formData.ID}`
				: `${process.env.REACT_APP_API_BASE_URL}/pesanan`;

			const response = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				throw new Error("Failed to save pesanan");
			}

			setShowAddPesananModal(false);
			await handleCalculateFinance(formData.Tanggal);
			window.location.reload();
		} catch (error) {
			console.error("Error saving pesanan:", error);
		}
	};

	const handleDeletePesanan = async (id) => {
		try {
			const pesananToDelete = pesananList.find((pesanan) => pesanan.ID === id);
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/pesanan/${id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to delete pesanan");
			}

			setPesananList(pesananList.filter((pesanan) => pesanan.ID !== id));
			if (pesananToDelete) {
				await handleCalculateFinance(pesananToDelete.Tanggal);
			}
			window.location.reload();
		} catch (error) {
			console.error("Error deleting pesanan:", error);
		}
	};

	const handleFilterChange = () => {
		setShowFilterModal(true);
	};

	const applyFilter = () => {
		setShowFilterModal(false);
	};

	const openDetailModal = (pesanan) => {
		setFormData(pesanan);
		setShowAddPesananModal(true);
	};

	const labels = Object.keys(
		pesananList.reduce((acc, pesanan) => {
			const month = new Date(pesanan.Tanggal).toLocaleString("default", {
				month: "short",
			});
			acc[month] = true;
			return acc;
		}, {})
	);

	const tertahanData = labels.map((month) => {
		const monthlyPesanan = pesananList.filter(
			(pesanan) =>
				new Date(pesanan.Tanggal).toLocaleString("default", {
					month: "short",
				}) === month
		);
		return monthlyPesanan.reduce(
			(sum, pesanan) => sum + (pesanan.Kredit || 0),
			0
		);
	});

	const cairData = labels.map((month) => {
		const monthlyPesanan = pesananList.filter(
			(pesanan) =>
				new Date(pesanan.Tanggal).toLocaleString("default", {
					month: "short",
				}) === month
		);
		return monthlyPesanan.reduce(
			(sum, pesanan) => sum + (pesanan.Debit || 0),
			0
		);
	});

	useEffect(() => {
		const fetchFinanceData = async () => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/api/keuntungan/${selectedYear}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error("Failed to fetch finance data");
				}
				const data = await response.json();
				setFinanceData(data);
			} catch (error) {
				console.error("Error fetching finance data:", error);
			}
		};

		fetchFinanceData();
	}, [selectedYear]);

	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const labelsbulanan = financeData.map((item) => monthNames[item.bulan - 1]);
	const keuntunganData = financeData.map((item) => item.keuntungan);

	const chartData = {
		labels: labelsbulanan,
		datasets: [
			{
				label: "Keuntungan",
				data: keuntunganData,
				borderColor: "rgba(75, 192, 192, 1)",
				backgroundColor: "rgba(75, 192, 192, 0.2)",
				fill: true,
				tension: 0.4,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
		},
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	return (
		<div className="bg-white min-h-screen p-4">
			<h1 className="text-2xl font-bold mb-6">Penjualan Dan Keuangan</h1>
			<div className="flex flex-col lg:flex-row gap-6">
				{/* Pendapatan Bulan Ini */}
				<div className="bg-gray-50 p-6 flex-1 lg:w-1/2">
					<h2 className="text-lg font-semibold mb-4">Pendapatan Bulan Ini</h2>
					<div className="space-y-4">
						{/* Dana Tertahan */}
						<div className="flex items-center bg-white shadow-md rounded-lg p-4 w-full">
							<div className="bg-orange-100 p-2 rounded-full">
								<span
									role="img"
									aria-label="cart"
								>
									🛒
								</span>
							</div>
							<div className="ml-4 flex-1">
								<p className="text-gray-700 font-medium">
									{financeSummary.total_danatertahan?.toLocaleString()}
								</p>
								<div className="flex items-center">
									<div className="w-full h-2 bg-gray-200 rounded-full">
										<div
											className="h-2 bg-orange-500 rounded-full"
											style={{ width: "52%" }}
										></div>
									</div>
									<span className="ml-2 text-gray-600">52%</span>
								</div>
								<p className="text-sm text-gray-500">Dana Tertahan</p>
							</div>
						</div>

						{/* Dana Cair */}
						<div className="flex items-center bg-white shadow-md rounded-lg p-4 w-full">
							<div className="bg-green-100 p-2 rounded-full">
								<span
									role="img"
									aria-label="truck"
								>
									🚚
								</span>
							</div>
							<div className="ml-4 flex-1">
								<p className="text-gray-700 font-medium">
									{financeSummary.total_danacair?.toLocaleString()}
								</p>
								<div className="flex items-center">
									<div className="w-full h-2 bg-gray-200 rounded-full">
										<div
											className="h-2 bg-green-500 rounded-full"
											style={{ width: "21%" }}
										></div>
									</div>
									<span className="ml-2 text-gray-600">21%</span>
								</div>
								<p className="text-sm text-gray-500">Dana Cair</p>
							</div>
						</div>

						{/* Rata Rata Pendapatan */}
						<div className="flex items-center bg-white shadow-md rounded-lg p-4 w-full">
							<div className="bg-blue-100 p-2 rounded-full">
								<span
									role="img"
									aria-label="plane"
								>
									✈️
								</span>
							</div>
							<div className="ml-4 flex-1">
								<p className="text-gray-700 font-medium">
									{financeSummary.ratarata_total_keuntungan?.toLocaleString()}
								</p>
								<div className="flex items-center">
									<div className="w-full h-2 bg-gray-200 rounded-full">
										<div
											className="h-2 bg-blue-500 rounded-full"
											style={{ width: "74%" }}
										></div>
									</div>
									<span className="ml-2 text-gray-600">74%</span>
								</div>
								<p className="text-sm text-gray-500">Rata Rata Pendapatan</p>
							</div>
						</div>

						<div className="flex items-center bg-white shadow-md rounded-lg p-4 w-full">
							<div className="bg-blue-100 p-2 rounded-full">
								<span
									role="img"
									aria-label="plane"
								>
									✈️
								</span>
							</div>
							<div className="ml-4 flex-1">
								{financeSummary.total_keuntungan?.toLocaleString()}
								<div className="flex items-center">
									<div className="w-full h-2 bg-gray-200 rounded-full">
										<div
											className="h-2 bg-blue-500 rounded-full"
											style={{ width: "74%" }}
										></div>
									</div>
									<span className="ml-2 text-gray-600">74%</span>
								</div>
								<p className="text-sm text-gray-500">Omzet</p>
							</div>
						</div>
					</div>
				</div>

				{/* Chart */}
				<div className="bg-gray-50 p-6 flex-1 lg:w-1/2">
					<h2 className="text-lg font-semibold mb-4">
						Pendapatan Perbulan untuk {selectedYear}
					</h2>
					<select
						className="mb-4 p-2 border border-gray-300 rounded"
						value={selectedYear}
						onChange={(e) => setSelectedYear(e.target.value)}
					>
						<option value="2025">2025</option>
						{/* Add more options as needed */}
					</select>
					<div style={{ width: "800px", height: "400px" }}>
						<Line
							data={chartData}
							options={options}
						/>
					</div>
				</div>
			</div>

			{/* Order History */}
			<div className="mt-6 bg-white p-6">
				<div className="flex justify-between mb-4">
					<h2 className="text-lg font-semibold">Riwayat Orderan</h2>
					<div className="flex space-x-2">
						<button
							className="bg-blue-500 text-white py-2 px-4 rounded"
							onClick={() => setShowAddPesananModal(true)}
						>
							Tambah Pesanan
						</button>
					</div>
				</div>
				<div className="overflow-x-auto">
					<table className="w-full text-left">
						<thead>
							<tr>
								<th className="py-2 border-b">Nomer Pesanan</th>
								<th className="border-b">ID Product</th>
								<th className="border-b">Modal Beli </th>
								<th className="border-b">Harga Jual </th>
								<th className="border-b">Untung Bersih</th>
								<th className="border-b">Nama Toko</th>
								<th className="border-b">Status</th>
								<th className="border-b">Penanggung Jawab</th>
								<th className="border-b text-right">Action</th>
							</tr>
						</thead>
						<tbody>
							{pesananList.length > 0 ? (
								pesananList.map((pesanan) => (
									<tr
										key={pesanan.ID}
										className="border-b"
									>
										<td>{pesanan.Invoice_ID}</td>
										<td className="flex items-center">{pesanan.Producttoko}</td>
										<td>{pesanan.Kredit?.toLocaleString()}</td>
										<td>{pesanan.Debit?.toLocaleString()}</td>
										<td>{pesanan.Untung?.toLocaleString()}</td>
										<td>{pesanan.Nama_Toko}</td>
										<td>{pesanan.status}</td>
										<td>{pesanan.PJ}</td>
										<td className="flex space-x-2 justify-end">
											<button
												className="bg-blue-500 text-white py-1 px-3 rounded"
												onClick={() => openDetailModal(pesanan)}
											>
												Detail Pesanan
											</button>
											<button
												className="bg-red-500 text-white py-1 px-3 rounded"
												onClick={() => handleDeletePesanan(pesanan.ID)}
											>
												Delete Pesanan
											</button>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td
										colSpan="9"
										className="text-center"
									>
										No data available
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{/* Add Pesanan Modal */}
			{showAddPesananModal && (
				<div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl">
						<h2 className="text-lg font-semibold mb-4">Tambah Pesanan</h2>
						<div className="grid grid-cols-2 gap-4">
							<label className="block">
								<span className="text-gray-700">Invoice ID</span>
								<input
									type="text"
									name="Invoice_ID"
									value={formData.Invoice_ID}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Tanggal</span>
								<input
									type="date"
									name="Tanggal"
									value={new Date(formData.Tanggal).toISOString().substr(0, 10)}
									onChange={(e) =>
										setFormData({
											...formData,
											Tanggal: new Date(e.target.value).getTime(),
										})
									}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Nama Toko</span>
								<input
									type="text"
									name="Nama_Toko"
									value={formData.Nama_Toko}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Kredit</span>
								<input
									type="number"
									name="Kredit"
									value={formData.Kredit}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Debit</span>
								<input
									type="number"
									name="Debit"
									value={formData.Debit}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Untung</span>
								<p className="w-full border border-gray-300 rounded-md p-2 bg-gray-100">
									{formData.Untung.toLocaleString()}
								</p>
							</label>
							<label className="block">
								<span className="text-gray-700">Status</span>
								<select
									name="status"
									value={formData.status}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								>
									<option value="Diproses">Diproses</option>
									<option value="Dikirim">Dikirim</option>
									<option value="Sukses">Sukses</option>
									<option value="Cancel">Cancel</option>
								</select>
							</label>
							<label className="block">
								<span className="text-gray-700">Catatan</span>
								<textarea
									name="Catatan"
									value={formData.Catatan}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Product ID</span>
								<input
									type="text"
									name="Producttoko"
									value={formData.Producttoko}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Nama</span>
								<input
									type="text"
									name="Nama"
									value={formData.Nama}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">No Hp</span>
								<input
									type="number"
									name="No_Hp"
									value={formData.No_Hp}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Kota</span>
								<input
									type="text"
									name="Kota"
									value={formData.Kota}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Provinsi</span>
								<input
									type="text"
									name="Provinsi"
									value={formData.Provinsi}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Alamat</span>
								<input
									type="text"
									name="Alamat"
									value={formData.Alamat}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
							<label className="block">
								<span className="text-gray-700">Penanggung Jawab (PJ)</span>
								<input
									type="text"
									name="PJ"
									value={formData.PJ}
									onChange={handleInputChange}
									className="w-full border border-gray-300 rounded-md p-2"
								/>
							</label>
						</div>
						<div className="flex justify-end mt-4">
							<button
								className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
								onClick={handleSavePesanan}
							>
								Simpan
							</button>
							<button
								className="bg-gray-500 text-white py-2 px-4 rounded"
								onClick={() => setShowAddPesananModal(false)}
							>
								Batal
							</button>
						</div>
					</div>
				</div>
			)}

			{/* Filter Modal */}
			{showFilterModal && (
				<div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-6 rounded-lg shadow-md w-80">
						<h2 className="text-lg font-semibold mb-4">Filter Tahun & Bulan</h2>
						<div className="mb-4">
							<label className="block text-sm font-medium">Tahun</label>
							<select
								className="mt-1 block w-full border border-gray-300 rounded-md"
								value={selectedYear}
								onChange={(e) => setSelectedYear(e.target.value)}
							>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
							</select>
						</div>
						<div className="flex justify-end">
							<button
								className="bg-blue-500 text-white py-2 px-4 rounded"
								onClick={applyFilter}
							>
								Apply
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Dashboard;
