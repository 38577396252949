// src/components/Navbar/index.jsx
import React, { useState, useEffect } from "react";
import Logo from "../asset/Logo.png";
import { Link, useLocation } from "react-router-dom";

const navLinks = [
	{ path: "/beranda", label: "Beranda" },
	{ path: "/harga", label: "Harga" },
	{ path: "/mitra", label: "Mitra" },
	{ path: "/bantuan", label: "Bantuan" },
];

function Navbar() {
	const [isOpen, setIsOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 0);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<nav
			className={`fixed w-full z-50 transition-all duration-300 ${
				isScrolled ? "bg-white shadow-md" : "bg-white"
			}`}
		>
			<div className="max-w-[1440px] mx-auto flex justify-between items-center px-4 md:px-8 lg:px-16 py-4">
				{/* Logo */}
				<Link
					to="/"
					className="flex items-center space-x-2"
				>
					<img
						src={Logo}
						alt="Pinter Jualan Logo"
						className="h-8"
					/>
				</Link>

				{/* Desktop Navigation */}
				<div className="hidden md:flex items-center space-x-8">
					{navLinks.map((link) => (
						<Link
							key={link.path}
							to={link.path}
							className={`text-sm font-medium hover:text-gray-600 transition-colors ${
								location.pathname === link.path ? "text-black" : "text-black"
							}`}
						>
							{link.label}
						</Link>
					))}
					<Link
						to="/login"
						className="bg-white text-black px-6 py-2 rounded-full border border-black text-sm font-medium hover:bg-black hover:text-white transition-all"
					>
						Masuk
					</Link>
				</div>

				{/* Mobile Menu Button */}
				<button
					className="md:hidden focus:outline-none"
					onClick={() => setIsOpen(!isOpen)}
					aria-label="Toggle menu"
				>
					<svg
						className="w-6 h-6"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						{isOpen ? (
							<path d="M6 18L18 6M6 6l12 12" />
						) : (
							<path d="M4 6h16M4 12h16M4 18h16" />
						)}
					</svg>
				</button>
			</div>

			{/* Mobile Menu */}
			<div
				className={`md:hidden bg-white border-t transition-all duration-300 ${
					isOpen ? "opacity-100 visible" : "opacity-0 invisible h-0"
				}`}
			>
				<div className="px-4 py-4">
					<div className="flex flex-col items-center space-y-4">
						{navLinks.map((link) => (
							<Link
								key={link.path}
								to={link.path}
								className={`text-sm font-medium hover:text-gray-600 transition-colors ${
									location.pathname === link.path ? "text-black" : "text-black"
								}`}
								onClick={() => setIsOpen(false)}
							>
								{link.label}
							</Link>
						))}
						<Link
							to="/login"
							className="bg-white text-black px-6 py-2 rounded-full border border-black text-sm font-medium hover:bg-black hover:text-white transition-all"
							onClick={() => setIsOpen(false)}
						>
							Masuk
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
