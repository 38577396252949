import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import Keuangan from "../components/Admin/Admin-Keuangan/Keuangan";

function Admin() {
	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-1 bg-gray-50">
				<Keuangan />
			</div>
		</div>
	);
}

export default Admin;
