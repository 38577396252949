import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import StoreSettings from "../components/Admin/Admin-Store/StoreSettings";

function Admin() {
	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-1 p-6 bg-gray-50">
				<StoreSettings />
			</div>
		</div>
	);
}

export default Admin;
