import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import Setting from "../components/Admin/Admin-Setting/Setting";

function Admin() {
	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-1 bg-gray-50">
				<Setting />
			</div>
		</div>
	);
}

export default Admin;
