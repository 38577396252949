import React from "react";
import test from "../../asset/Illustration.png";

const FeaturesHeader = () => (
	<div className="flex items-center mb-8">
		<h2 className="text-2xl font-bold bg-lime-300 inline-block px-3 py-1 rounded mr-4">
			Fitur
		</h2>
		<p>
			Fitur yang kami tawarkan secara gratis kepada seluruh mitra kami cukup
			gabung dan rasakan semua fiturnya.
		</p>
	</div>
);

const FeatureCard = ({ title, bgColor, imgSrc }) => (
	<div
		className={`rounded-xl p-6 flex items-center justify-between ${bgColor}`}
	>
		<div className="max-w-xs">
			<h3 className="text-lg font-bold bg-black text-white inline-block px-2 py-1 rounded">
				{title}
			</h3>
		</div>
		<img
			src={imgSrc}
			alt={title}
			className="w-1/3 rounded-lg"
		/>
	</div>
);

const Features = () => (
	<section className="bg-white py-8">
		<div className="max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16">
			<FeaturesHeader />
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
				<FeatureCard
					title="Otomatis update Product Seo"
					bgColor="bg-gray-100"
					imgSrc={test}
				/>
				<FeatureCard
					title="Otomatis update Product supplier"
					bgColor="bg-lime-300"
					imgSrc={test}
				/>
				<FeatureCard
					title="Jualan dengan Toko tak terbatas"
					bgColor="bg-black text-white"
					imgSrc={test}
				/>
				<FeatureCard
					title="Kelola Pesanan dan product"
					bgColor="bg-gray-100"
					imgSrc={test}
				/>
				<FeatureCard
					title="Management Chat + AI"
					bgColor="bg-lime-300"
					imgSrc={test}
				/>
				<FeatureCard
					title="Analytics dan Tracking Toko"
					bgColor="bg-black text-white"
					imgSrc={test}
				/>
			</div>
		</div>
	</section>
);

export default Features;
