import React, { useState, useEffect } from "react";
function ChatList({
	view,
	setView,
	contacts,
	stores,
	onStoreClick,
	onContactClick,
	onBackToStores,
}) {
	return (
		<div className="w-full h-full bg-white shadow-lg flex flex-col">
			<div className="flex justify-between items-center p-2">
				<h2 className="text-lg font-bold">Message</h2>
				{view === "contacts" && (
					<button
						className="text-blue-500 font-semibold"
						onClick={onBackToStores}
					>
						Back to Stores
					</button>
				)}
			</div>
			<input
				type="text"
				placeholder="Search"
				className="w-full p-1 border-t border-b"
				aria-label="Search contacts or stores"
			/>
			<div className="flex justify-between p-2 text-xs">
				{view === "stores" && (
					<button
						className={`text-blue-500 font-semibold underline`}
						onClick={() => setView("stores")}
					>
						Toko
					</button>
				)}
				{view === "contacts" && (
					<>
						<button
							className={`text-blue-500 font-semibold underline`}
							onClick={() => setView("contacts")}
						>
							Semua
						</button>
						<button
							className={`text-blue-500 font-semibold`}
							onClick={() => setView("contacts")}
						>
							Terbaru
						</button>
					</>
				)}
			</div>
			<div className="space-y-2 overflow-y-auto flex-1 p-2">
				{view === "stores"
					? stores.map((store, index) => (
							<div
								key={index}
								className="flex items-center p-1 rounded-lg"
								onClick={() => onStoreClick(store)}
							>
								<img
									src={store.photo || "https://via.placeholder.com/30"}
									alt={store.name}
									className="w-8 h-8 rounded-full mr-2"
								/>
								<div className="flex-1">
									<h3 className="font-semibold text-xs">{store.name}</h3>
									<p
										className={`text-xs ${
											store.status === "cookie mati"
												? "text-red-500"
												: "text-green-500"
										}`}
									>
										{store.status}
									</p>
									<p className="text-red-500 text-xs">
										{store.unreadCount} unread messages
									</p>
								</div>
							</div>
					  ))
					: contacts.map((contact, index) => (
							<div
								key={index}
								className="flex items-center p-1 rounded-lg"
								onClick={() => onContactClick(contact)}
							>
								<img
									src={
										contact.avatar ||
										`https://via.placeholder.com/30?text=${contact.to_name.charAt(
											0
										)}`
									}
									alt={contact.to_name}
									className="w-8 h-8 rounded-full mr-2"
								/>
								<div className="flex-1">
									<h3 className="font-semibold text-xs">{contact.to_name}</h3>
									<p className="text-gray-500 text-xs">
										{contact.latest_message_content?.text}
									</p>
								</div>
								<span className="text-red-400 text-xs">
									{contact.unread_count} unread
								</span>
							</div>
					  ))}
			</div>
		</div>
	);
}

function ChatApp() {
	const [view, setView] = useState("stores");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedStore, setSelectedStore] = useState(null);
	const [chatMessages, setChatMessages] = useState([]);
	const [stores, setStores] = useState([]);
	const [chatList, setChatList] = useState([]);
	const [messageText, setMessageText] = useState("");
	const [productDetails, setProductDetails] = useState(null);

	const fetchUnreadMessages = async (storeId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/chatunread/${storeId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				if (response.status === 401) {
					return { unreadCount: 0, status: "cookie mati" };
				}
				throw new Error("Failed to fetch unread messages");
			}

			const data = await response.json();
			return { unreadCount: data.total_unread_conv_count, status: "active" };
		} catch (error) {
			console.error("Error fetching unread messages:", error);
			return { unreadCount: 0, status: "cookie mati" };
		}
	};

	const fetchStores = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			const formattedStores = await Promise.all(
				data.map(async (store) => {
					const { unreadCount, status } = await fetchUnreadMessages(store._id);
					return {
						name: store.Nama_Toko,
						id: store._id,
						photo: store.Foto.filename
							? `${process.env.REACT_APP_API_BASE_URL}${store.Foto.path}`
							: null,
						phone: store.Phone,
						status: status,
						unreadCount: unreadCount,
					};
				})
			);
			setStores(formattedStores);
		} catch (error) {
			console.error("Failed to fetch stores:", error);
			setStores([]);
		}
	};

	const fetchChatList = async (storeId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/chatlist/${storeId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to fetch chat list");
			}

			const data = await response.json();
			setChatList(data);
		} catch (error) {
			console.error("Error fetching chat list:", error);
			setChatList([]);
		}
	};

	const fetchChatDetails = async (storeId, chatId, shopId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/chatdetails/${storeId}/${chatId}/${shopId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to fetch chat details");
			}

			const data = await response.json();
			setChatMessages(data.messages);
			setProductDetails(data.productDetails[0]); // Assuming one product detail
		} catch (error) {
			console.error("Error fetching chat details:", error);
			setChatMessages([]);
			setProductDetails(null);
		}
	};

	const sendMessage = async () => {
		if (!messageText.trim()) return;

		try {
			const token = localStorage.getItem("token");

			const selectedChat = chatList.find(
				(chat) => chat.id === selectedContact.id
			);

			if (!selectedChat) {
				console.error("Selected chat not found");
				return;
			}

			const idToko = selectedStore.id;
			const idChat = selectedChat.id;
			const idShopeeSaya = selectedChat.shop_id;
			const idShopeeTarget = selectedChat.to_id;

			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/sendmessage/${idToko}/${idChat}/${idShopeeSaya}/${idShopeeTarget}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ messageText }),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to send message");
			}

			const result = await response.json();
			const newMessage = {
				from_id: idShopeeSaya,
				to_id: idShopeeTarget,
				content: {
					text: result.data.content.text,
				},
				created_at: new Date().toISOString(),
			};

			setChatMessages((prevMessages) => [...prevMessages, newMessage]);
			setMessageText("");

			// Refresh chat list after sending a message
			fetchChatList(selectedStore.id);
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	const handleStoreClick = (store) => {
		setSelectedStore(store);
		fetchChatList(store.id);
		setView("contacts");
	};

	const handleContactClick = (contact) => {
		setSelectedContact(contact);
		fetchChatDetails(selectedStore.id, contact.id, contact.shop_id);
		setView("chat");
	};

	const resetSelection = () => {
		setSelectedStore(null);
		setSelectedContact(null);
		setChatList([]);
		setChatMessages([]);
		setProductDetails(null); // Clear product details
		setView("stores");
	};

	useEffect(() => {
		fetchStores();

		const interval = setInterval(() => {
			fetchStores();
		}, 120000); // 2 minutes

		return () => clearInterval(interval); // Cleanup on unmount
	}, []);

	useEffect(() => {
		if (selectedStore) {
			const interval = setInterval(() => {
				fetchChatList(selectedStore.id);
			}, 120000); // 2 minutes

			return () => clearInterval(interval); // Cleanup on unmount
		}
	}, [selectedStore]);

	function ProductCard({ product }) {
		return (
			<div className="flex items-center p-4 border rounded-lg shadow-md bg-white">
				<img
					src={product.full_item_image_url}
					alt={product.product_name}
					className="w-16 h-16 mr-4"
				/>
				<div>
					<h4 className="text-lg font-semibold">{product.title}</h4>
					<h5 className="text-sm font-semibold text-red-500">
						{product.product_name}
					</h5>
					<p className="text-red-500 font-bold">
						Rp{" "}
						{parseFloat(product.price_info.actual_prices[0]).toLocaleString(
							"id-ID"
						)}
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex h-screen overflow-hidden bg-gray-100">
			<div className="block md:hidden w-full">
				{view === "stores" ? (
					<ChatList
						view={view}
						setView={setView}
						contacts={chatList}
						stores={stores}
						onStoreClick={handleStoreClick}
						onContactClick={handleContactClick}
					/>
				) : (
					<div className="w-full h-full bg-white shadow-lg flex flex-col">
						<div className="flex justify-between items-center p-2 border-b">
							<div>
								<button
									className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
									onClick={() => fetchChatList(selectedStore.id)}
								>
									Refresh
								</button>
								<button
									className="bg-blue-500 text-white px-3 py-1 rounded"
									onClick={resetSelection}
								>
									Back to Stores
								</button>
							</div>
						</div>

						{productDetails && <ProductCard product={productDetails} />}

						<div className="flex-1 overflow-y-auto p-4 space-y-4">
							{chatMessages.map((message, index) => (
								<div
									key={index}
									className={`flex ${
										message.from_id === selectedContact.to_id
											? "justify-start"
											: "justify-end"
									}`}
								>
									<div
										className={`${
											message.from_id === selectedContact.to_id
												? "bg-gray-200"
												: "bg-blue-500 text-white"
										} p-3 rounded-lg max-w-xs shadow-md`}
									>
										<p className="text-sm">
											{message.content?.text ? (
												<p className="text-sm">{message.content.text}</p>
											) : message.content?.url ? (
												<img
													src={message.content.url}
													alt="Chat content"
													className="max-w-full h-auto"
												/>
											) : (
												<p className="text-sm">No content</p>
											)}
										</p>
										<span className="text-xs text-gray-600 mt-1 block">
											{new Date(message.created_at).toLocaleTimeString([], {
												hour: "2-digit",
												minute: "2-digit",
											})}
										</span>
									</div>
								</div>
							))}
						</div>

						<div className="flex items-center p-2 border-t">
							<input
								type="text"
								placeholder="Type a message..."
								className="w-full p-2 border rounded-l-lg"
								aria-label="Type a message"
								value={messageText}
								onChange={(e) => setMessageText(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										sendMessage();
									}
								}}
							/>
							<button
								className="bg-blue-500 text-white p-2 rounded-r-lg"
								aria-label="Send message"
								onClick={sendMessage}
							>
								Send
							</button>
						</div>
					</div>
				)}
			</div>

			<div className="hidden md:flex w-full">
				<div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-white shadow-lg flex flex-col">
					<div className="flex justify-between items-center p-2">
						<div>
							<button
								className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
								onClick={() => fetchChatList(selectedStore.id)}
							>
								Refresh
							</button>
							<button
								className="bg-blue-500 text-white px-3 py-1 rounded"
								onClick={resetSelection}
							>
								Back to Stores
							</button>
						</div>
					</div>
					<ChatList
						view={view}
						setView={setView}
						contacts={chatList}
						stores={stores}
						onStoreClick={handleStoreClick}
						onContactClick={handleContactClick}
						onBackToStores={resetSelection}
					/>
				</div>

				<div className="flex-1 bg-white shadow-lg flex flex-col">
					{productDetails && <ProductCard product={productDetails} />}

					<div className="flex-1 overflow-y-auto p-4 space-y-4">
						{chatMessages.map((message, index) => (
							<div
								key={index}
								className={`flex ${
									message.from_id === selectedContact.to_id
										? "justify-start"
										: "justify-end"
								}`}
							>
								<div
									className={`${
										message.from_id === selectedContact.to_id
											? "bg-gray-200"
											: "bg-blue-500 text-white"
									} p-3 rounded-lg max-w-xs shadow-md`}
								>
									<p className="text-sm">
										{message.content?.text ? (
											<p className="text-sm">{message.content.text}</p>
										) : message.content?.url ? (
											<img
												src={message.content.url}
												alt="Chat content"
												className="max-w-full h-auto"
											/>
										) : (
											<p className="text-sm">No content</p>
										)}
									</p>
									<span className="text-xs text-gray-600 mt-1 block">
										{new Date(message.created_at).toLocaleTimeString([], {
											hour: "2-digit",
											minute: "2-digit",
										})}
									</span>
								</div>
							</div>
						))}
					</div>

					<div className="flex items-center p-2 border-t">
						<input
							type="text"
							placeholder="Type a message..."
							className="w-full p-2 border rounded-l-lg"
							aria-label="Type a message"
							value={messageText}
							onChange={(e) => setMessageText(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									sendMessage();
								}
							}}
						/>
						<button
							className="bg-blue-500 text-white p-2 rounded-r-lg"
							aria-label="Send message"
							onClick={sendMessage}
						>
							Send
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChatApp;
