import React, { useState, useEffect } from "react";
import axios from "axios";

function Dashboard() {
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);
	const [storeErrors, setStoreErrors] = useState({});

	// Function to fetch data from the API
	const fetchData = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/order`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log("Fetched data:", response.data); // Debugging line
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(error.message);
		}
	};

	// Use useEffect to fetch data when the component mounts
	useEffect(() => {
		fetchData();
	}, []);

	// Calculate totals for each order status, excluding "belum dibayar", "sukses", and "selesai"
	const calculateTotals = (data) => {
		const totals = {
			"perlu dikirim (belum diproses)": 0,
			"perlu dikirim (sudah diproses)": 0,
			dikirim: 0,
			"cancel (belum dikonfirmasi)": 0,
			"cancel (sudah dikonfirmasi)": 0,
			"pengembalian (belum disetujui)": 0,
		};

		data.forEach((store) => {
			if (store && store.orders) {
				Object.keys(totals).forEach((key) => {
					totals[key] += store.orders[key] || 0;
				});
			}
		});

		return totals;
	};

	const totals = calculateTotals(data);

	return (
		<div className="relative min-h-screen md:flex bg-gray-100">
			{/* Main content */}
			<div className="flex-1 p-4">
				<div className="space-y-6">
					{/* Statistics Cards */}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
						{Object.entries(totals).map(([key, value]) => (
							<div
								key={key}
								className="bg-white p-6 md:p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-300"
							>
								<p className="text-lg md:text-sm text-gray-600">{key}</p>
								<h2 className="text-3xl md:text-2xl font-bold text-blue-600">
									{value}
								</h2>
							</div>
						))}
					</div>

					{/* Display Error */}
					{error && <p className="text-red-500">Error: {error}</p>}

					{/* Store Details in Table with Columns */}
					<div className="mt-6">
						{data.map((store, index) => {
							const storeError = storeErrors[store?.id];
							return (
								<div
									key={index}
									className="bg-white p-4 rounded-lg shadow mb-4"
								>
									<h3 className="text-xl font-bold text-blue-700">
										{store?.namatoko}
									</h3>
									{storeError && (
										<p className="text-red-500">
											Error fetching orders: {storeError}
										</p>
									)}
									<table className="min-w-full divide-y divide-gray-200">
										<thead>
											<tr className="bg-blue-50">
												{store?.orders &&
													Object.keys(store.orders).map(
														(key) =>
															key !== "belum dibayar" &&
															key !== "sukses" &&
															key !== "selesai" && (
																<th
																	key={key}
																	className="px-4 py-2 text-left text-sm font-medium text-gray-700 whitespace-nowrap"
																>
																	{key}
																</th>
															)
													)}
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											<tr className="hover:bg-blue-50 transition-colors duration-200">
												{store?.orders &&
													Object.entries(store.orders).map(
														([key, value], idx) =>
															key !== "belum dibayar" &&
															key !== "sukses" &&
															key !== "selesai" && (
																<td
																	key={idx}
																	className="px-4 py-2 whitespace-nowrap text-sm text-gray-600"
																>
																	{value}
																</td>
															)
													)}
											</tr>
										</tbody>
									</table>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
