import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const allProducts = [
	{
		id: 1,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Abadi Sejahtera",
	},
	{
		id: 2,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Abadi Jaya",
	},
	{
		id: 3,
		name: "SUN Pengering Kutek",
		productID: "27710023218",
		date: "2024-09-27",
		store: "Grosir Sinar Jaya",
	},
];

const stores = [
	{ name: "Grosir Abadi Sejahtera", id: "abadi-sejahtera" },
	{ name: "Grosir Abadi Jaya", id: "abadi-jaya" },
	{ name: "Grosir Sinar Jaya", id: "sinar-jaya" },
	{ name: "Grosir Sri Rejeki", id: "sri-rejeki" },
	{ name: "Grosir Jaya Mas", id: "jaya-mas" },
];

function DataProduct() {
	const navigate = useNavigate();
	const [selectedStore, setSelectedStore] = useState(stores[0].name);

	const filteredProducts = allProducts.filter(
		(product) => product.store === selectedStore
	);

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Product</h1>

			<div className="flex flex-wrap gap-2 mb-4">
				{stores.map((store) => (
					<button
						key={store.id}
						className={`px-4 py-2 rounded ${
							selectedStore === store.name
								? "bg-green-500 text-white"
								: "bg-orange-400 text-white"
						}`}
						onClick={() => setSelectedStore(store.name)}
					>
						{store.name}
					</button>
				))}
			</div>

			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
				<div className="bg-white p-4 shadow rounded-lg">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Melalui ID Product
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Masukan ID"
						/>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Melalui Index
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Index Awal"
						/>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="Index Akhir"
						/>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>
				</div>

				<div className="bg-white p-4 shadow rounded-lg">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Informasi Semua Product
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Semua Product Stok Kosong
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>

					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Perbarui Semua Product Update Harga
						</h4>
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Scrape
							</button>
							<button className="bg-blue-500 text-white w-1/2 py-2 rounded">
								Update
							</button>
						</div>
					</div>
				</div>

				<div className="bg-white p-4 shadow rounded-lg">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">Setting Kenaikan Harga</h4>
						{[
							"10K - 20K",
							"21K - 50K",
							"51K - 100K",
							"101K - 200K",
							"201K - 400K",
							"400K ++",
						].map((placeholder, index) => (
							<input
								key={index}
								className="w-full p-2 border rounded mb-2"
								placeholder={placeholder}
							/>
						))}
						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-full py-2 rounded">
								Save
							</button>
						</div>
					</div>
				</div>

				<div className="bg-white p-4 shadow rounded-lg">
					<h3 className="text-xl font-semibold mb-4 text-center">
						{selectedStore}
					</h3>
					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Setting Update Product Setiap
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="10K - 20K"
						/>

						<div className="mb-4">
							<h5 className="font-medium text-sm mb-2">
								Product yang ingin diperbarui:
							</h5>
							<div className="space-y-2">
								{["Stok", "Harga", "Stok dan Harga", "ALL"].map(
									(label, index) => (
										<label
											key={index}
											className="flex items-center"
										>
											<input
												type="checkbox"
												className="mr-2"
											/>
											<span>{label}</span>
										</label>
									)
								)}
							</div>
						</div>

						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-full py-2 rounded">
								Save
							</button>
						</div>
					</div>

					<div className="mb-6">
						<h4 className="font-medium text-sm mb-2">
							Setting Scrape Product Setiap
						</h4>
						<input
							className="w-full p-2 border rounded mb-2"
							placeholder="10K - 20K"
						/>

						<div className="mb-4">
							<h5 className="font-medium text-sm mb-2">
								Product yang ingin diperbarui:
							</h5>
							<div className="space-y-2">
								{["Stok", "Harga", "Stok dan Harga", "ALL"].map(
									(label, index) => (
										<label
											key={index}
											className="flex items-center"
										>
											<input
												type="checkbox"
												className="mr-2"
											/>
											<span>{label}</span>
										</label>
									)
								)}
							</div>
						</div>

						<div className="flex justify-between space-x-2">
							<button className="bg-blue-500 text-white w-full py-2 rounded">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="overflow-x-auto mt-6">
				<table className="table-auto w-full bg-white shadow rounded-lg">
					<thead>
						<tr>
							<th className="px-4 py-2">No</th>
							<th className="px-4 py-2">Nama Product</th>
							<th className="px-4 py-2">ID Product</th>
							<th className="px-4 py-2">Tanggal Update</th>
							<th className="px-4 py-2">Tanggal Scape</th>
							<th className="px-4 py-2">Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredProducts.map((product, index) => (
							<tr key={product.id}>
								<td className="border px-4 py-2">{index + 1}</td>
								<td className="border px-4 py-2">{product.name}</td>
								<td className="border px-4 py-2">{product.productID}</td>
								<td className="border px-4 py-2">{product.date}</td>
								<td className="border px-4 py-2">{product.date}</td>
								<td
									className="border px-4 py-2 text-blue-500 cursor-pointer"
									onClick={() => navigate(`/product/${product.id}`)}
								>
									Detail Product
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default DataProduct;
