import React from "react";
import JakartaIcon from "../../asset/Jakarta.png";
// import TangerangIcon from "../../asset/Tangerang.png";
// import SurabayaIcon from "../../asset/Surabaya.png";
// import SemarangIcon from "../../asset/Semarang.png";
// import BaliIcon from "../../asset/Bali.png";
// import BandungIcon from "../../asset/Bandung.png";

const BranchCard = ({ icon, title, status, description }) => (
	<div className="bg-gray-100 border-2 border-black rounded-lg shadow-lg p-4 flex flex-col items-center text-center">
		<img
			src={icon}
			alt={title}
			className="h-16 mb-4"
		/>
		<h3 className="text-lg font-bold mb-2">{title}</h3>
		<p className="text-sm text-gray-600 mb-4">{status}</p>
		<hr className="w-full border-t border-black mb-4" />
		<p className="text-sm text-gray-700">{description}</p>
	</div>
);

const BranchesSection = () => (
	<section className="bg-white py-8">
		<div className="max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16">
			<div className="flex items-center justify-between mb-8">
				<h2 className="text-2xl font-bold bg-lime-300 inline-block px-3 py-1 rounded">
					Cabang
				</h2>
				{/* <span className="text-gray-700">Peta Gudang Kami</span> */}
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Jakarta"
					status="Kantor Pusat"
					description="Akan mengelola Proses pesanan Se DKI jakarta kecuali meliputi ( jakarta barat, jakarta timur, jakarta utara, jakarta pusat, jakarta selatan, dan bekasi"
				/>
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Tangerang"
					status="Proses Pembangunan"
					description="Akan mengelola Proses pesanan Meliputi Tangerang dan Tangerang Selatan"
				/>
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Surabaya"
					status="Proses Pembangunan"
					description="Akan mengelola pesanan meliputin surabaya, malang, kediri, jember, banyuwangi, dan madiun"
				/>
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Semarang"
					status="Proses Pembangunan"
					description="Akan mengelola pesanan meliputi Semarang, Solo, Yogyakarta, Pekalongan, Tegal, Magelang, Purwokerto, Kudus, dan Cilacap."
				/>
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Bali"
					status="Proses Pembangunan"
					description="Akan mengelola pesanan meliputi Denpasar, Badung, Gianyar, Tabanan, Singaraja, Klungkung, Bangli, Karangasem, dan Jembrana."
				/>
				<BranchCard
					icon={JakartaIcon}
					title="Cabang Bandung"
					status="Proses Pembangunan"
					description="Akan mengelola pesanan meliputi Bandung, Bogor, Depok, Cirebon, Tasikmalaya, Sukabumi, dan Cimahi."
				/>
			</div>
		</div>
	</section>
);

export default BranchesSection;
