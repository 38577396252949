import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ProductTable from "./ProductTable";
import ActionButtons from "./ActionButtons";
import ProductModal from "./ProductModal";
import PriceIncreaseModal from "./PriceIncreaseModal";
import Update from "./Update";
import Scrape from "./Scrape";
import UpdateAllDataModal from "./UpdateALLModal";

Modal.setAppElement("#root");

function DataProduct() {
	const navigate = useNavigate();
	const [stores, setStores] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [popupMessage, setPopupMessage] = useState({
		page1: [],
		page2: [],
		page3: [],
		page4: [],
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isCardModalOpen, setIsCardModalOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [isscrape, setIsscrape] = useState(false);
	const [isAllDataModalOpen, setIsAllDataModalOpen] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [newProduct, setNewProduct] = useState({
		Id_product: "",
		Nama_Product_Shopee: "",
		Nama_Product_Supplier: "",
		Varian_Shopee: [{ Nama: "", Harga: 0, Stok: 0 }],
		Varian_Suplier: [{ Nama: "", Harga: 0, Stok: 0 }],
		Berat_Product_Shopee: 0,
		Berat_Product_Suplier: 0,
		Tanggal_Update: "",
		Tanggal_Scrape: "",
		Deskripsi_Product_Shopee: "",
		Deskripsi_Product_Suplier: "",
		URL: "",
		Varian_Shopee_1: "",
	});
	const [priceIncreases, setPriceIncreases] = useState({
		"0-9999": { value: 0, usePercentage: false },
		"10000-20000": { value: 0, usePercentage: false },
		"21000-50000": { value: 0, usePercentage: false },
		"51000-100000": { value: 0, usePercentage: false },
		"101000-200000": { value: 0, usePercentage: false },
		"201000-400000": { value: 0, usePercentage: false },
		"400000++": { value: 0, usePercentage: false },
	});

	useEffect(() => {
		const fetchStores = async () => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/toko`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setStores(data);
				console.log("Stores:", data);
				if (data.length > 0) {
					setSelectedStore(data[0]);
					fetchProducts(data[0]._id);
				}
			} catch (error) {
				console.error("Failed to fetch stores:", error);
			}
		};

		fetchStores();
	}, []);

	useEffect(() => {
		setIsPopupVisible(true);
	}, []);

	useEffect(() => {
		// Filter products based on search query
		setFilteredProducts(
			products.filter((product) =>
				product.Id_product.toString()
					.toLowerCase()
					.includes(searchQuery.toLowerCase())
			)
		);
	}, [searchQuery, products]);

	const fetchProducts = async (storeId, page = 1) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko/${storeId}/producttoko?page=${page}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setProducts(data.products);
			setCurrentPage(data.currentPage);
			setTotalPages(data.totalPages);

			console.log("Products:", data);

			// Existing logic for processing products and setting popup messages...
			const page1Messages = [];
			const page2Messages = [];
			const page3Messages = [];
			const page4Messages = [];

			data.products.forEach((product) => {
				const shopeeVariants = product.Varian_Shopee[0];
				const supplierVariants = product.Varian_Suplier[0];

				if (shopeeVariants && supplierVariants) {
					const isSameLength =
						shopeeVariants.length === supplierVariants.length;
					if (!isSameLength) {
						page1Messages.push(
							`Product: ${product.Nama_Product_Shopee}, Jumlah Variasi: Tidak Sama`
						);
					} else {
						shopeeVariants.forEach((variant, i) => {
							if (variant.Nama !== supplierVariants[i].Nama) {
								page2Messages.push(
									`Product: ${product.Nama_Product_Shopee}, Varian index ${
										i + 1
									}: Nama tidak sama (${variant.Nama} vs ${
										supplierVariants[i].Nama
									})`
								);
							}

							const shopeeStock = variant.Stok;
							const supplierStock = supplierVariants[i].Stok;

							if (supplierStock === 0) {
								page3Messages.push(
									`Product: ${product.Nama_Product_Shopee}, Varian index ${
										i + 1
									}: Stok habis`
								);
							} else if (supplierStock < shopeeStock) {
								page3Messages.push(
									`Product: ${product.Nama_Product_Shopee}, Varian index ${
										i + 1
									}: Stok berkurang (${supplierStock} vs ${shopeeStock})`
								);
							} else if (supplierStock > shopeeStock) {
								page3Messages.push(
									`Product: ${product.Nama_Product_Shopee}, Varian index ${
										i + 1
									}: Stok tambah (${supplierStock} vs ${shopeeStock})`
								);
							}

							const shopeePrice = variant.Harga;
							const supplierPrice = supplierVariants[i].Harga;

							if (supplierPrice > shopeePrice) {
								page4Messages.push(
									`Product: ${product.Nama_Product_Shopee}, Varian index ${
										i + 1
									}: Harga supplier naik (${supplierPrice} vs ${shopeePrice})`
								);
							}
						});
					}
				}
			});

			setPopupMessage({
				page1: page1Messages,
				page2: page2Messages,
				page3: page3Messages,
				page4: page4Messages,
			});
		} catch (error) {
			console.error("Failed to fetch products:", error);
		}
	};

	const handleStoreSelect = (store) => {
		setSelectedStore(store);
		fetchProducts(store._id);
	};

	const handlePriceIncreaseChange = (range, field, value) => {
		setPriceIncreases((prev) => ({
			...prev,
			[range]: {
				...prev[range],
				[field]: field === "value" ? parseInt(value) : value,
			},
		}));
	};

	const applyPriceIncreases = async () => {
		try {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id"); // Retrieve the ID from local storage

			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/updateharga/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(priceIncreases),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to update price increases");
			}

			alert("Price increases saved successfully!");
		} catch (error) {
			console.error("Error saving price increases:", error);
			alert("There was an error saving the price increases.");
		}
	};

	const fetchProductById = async (productId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/productToko/${productId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to fetch product");
			}

			const product = await response.json();
			setFilteredProducts([product]);
		} catch (error) {
			console.error("Error fetching product by ID:", error);
		}
	};

	const handleSearch = () => {
		if (searchQuery.trim()) {
			fetchProductById(searchQuery.trim());
		}
	};

	const combinedProducts = selectedProduct
		? [
				selectedProduct,
				...products.filter((p) => p.Id_product !== selectedProduct.Id_product),
		  ]
		: products;

	const addProduct = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko/${selectedStore._id}/producttoko`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(newProduct),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to add product");
			}

			const data = await response.json();
			setProducts([...products, data.productToko]);
			alert("Product added successfully!");
			setNewProduct({
				Id_product: "",
				Nama_Product_Shopee: "",
				Nama_Product_Supplier: "",
				Varian_Shopee: [{ Nama: "", Harga: 0, stok: 0 }],
				Varian_Suplier: [{ Nama: "", Harga: 0, stok: 0 }],
				Berat_Product_Shopee: 0,
				Berat_Product_Suplier: 0,
				Tanggal_Update: "",
				Tanggal_Scrape: "",
				Deskripsi_Product_Shopee: "",
				Deskripsi_Product_Suplier: "",
				URL: "",
				Varian_Shopee_1: "",
			});
			setIsAddModalOpen(false);
		} catch (error) {
			console.error("There was an error adding the product:", error);
		}
	};

	const deleteProduct = async (productId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/producttoko/${productId}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Failed to delete product");
			}

			setProducts(products.filter((product) => product._id !== productId));
			setFilteredProducts(
				filteredProducts.filter((product) => product._id !== productId)
			);
			alert("Product deleted successfully!");
		} catch (error) {
			console.error("There was an error deleting the product:", error);
		}
	};

	const updateProduct = async (product) => {
		try {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id");
			const storeId = selectedStore._id;
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/producttoko/${id}/${product.Id_product}/${storeId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(product),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to update product");
			}

			const updatedProduct = await response.json();
			setProducts(
				products.map((p) =>
					p.Id_product === updatedProduct.Id_product ? updatedProduct : p
				)
			);
			setFilteredProducts(
				filteredProducts.map((p) =>
					p.Id_product === updatedProduct.Id_product ? updatedProduct : p
				)
			);
			alert("Product updated successfully!");

			const detailsResponse = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/product-toko-update/${storeId}/${product.Id_product}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!detailsResponse.ok) {
				throw new Error("Failed to fetch product details");
			}

			const productDetails = await detailsResponse.json();
			console.log("Product details:", productDetails);

			setIsModalOpen(false);
		} catch (error) {
			console.error("There was an error updating the product:", error);
			alert("Error updating product");
		}
	};

	const addVariantDetail = (type) => {
		const newVariant = { _id: Date.now(), Nama: "", Harga: 0, Stok: 0 };
		if (type === "shopee") {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Shopee: [...prev.Varian_Shopee, newVariant],
			}));
		} else {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Suplier: [...prev.Varian_Suplier, newVariant],
			}));
		}
	};

	const removeVariantDetail = (type, index) => {
		if (type === "shopee") {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Shopee: prev.Varian_Shopee.filter((_, i) => i !== index),
			}));
		} else {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Suplier: prev.Varian_Suplier.filter((_, i) => i !== index),
			}));
		}
	};

	const addVariant = (type) => {
		const newVariant = { _id: Date.now(), Nama: "", Harga: 0, Stok: 0 };
		if (type === "shopee") {
			setNewProduct((prev) => ({
				...prev,
				Varian_Shopee: [...prev.Varian_Shopee, newVariant],
			}));
		} else {
			setNewProduct((prev) => ({
				...prev,
				Varian_Suplier: [...prev.Varian_Suplier, newVariant],
			}));
		}
	};

	const removeVariant = (type, index) => {
		if (type === "shopee") {
			setNewProduct((prev) => ({
				...prev,
				Varian_Shopee: prev.Varian_Shopee.filter((_, i) => i !== index),
			}));
		} else {
			setNewProduct((prev) => ({
				...prev,
				Varian_Suplier: prev.Varian_Suplier.filter((_, i) => i !== index),
			}));
		}
	};

	const handlePopupClick = (productName) => {
		const product = products.find((p) => p.Nama_Product_Shopee === productName);
		if (product) {
			setSelectedProduct(product);
			setIsModalOpen(true);
		} else {
			console.error("Product not found:", productName);
		}
	};

	const renderPopupMessages = (messages) => {
		return messages.map((message, index) => {
			const productName = message.split(",")[0].split(":")[1].trim();
			return (
				<div
					key={index}
					className="cursor-pointer"
					onClick={() => handlePopupClick(productName)}
				>
					{index + 1}. {message}
				</div>
			);
		});
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			fetchProducts(selectedStore._id, currentPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			fetchProducts(selectedStore._id, currentPage - 1);
		}
	};

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Product</h1>

			<div className="flex mb-4">
				<input
					type="text"
					placeholder="Search by ID Product"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					className="p-2 border rounded mr-2"
				/>
				<button
					onClick={handleSearch}
					className="px-4 py-2 bg-blue-500 text-white rounded"
				>
					Cari
				</button>
			</div>

			<Modal
				isOpen={isPopupVisible}
				onRequestClose={() => setIsPopupVisible(false)}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						padding: "20px",
						borderRadius: "10px",
						width: "80%",
						maxWidth: "500px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
					},
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					},
				}}
			>
				<h2>Popup Messages</h2>
				{currentPage === 1 && popupMessage.page1.length > 0 && (
					<div>{renderPopupMessages(popupMessage.page1)}</div>
				)}
				{currentPage === 2 && popupMessage.page2.length > 0 && (
					<div>{renderPopupMessages(popupMessage.page2)}</div>
				)}
				{currentPage === 3 && popupMessage.page3.length > 0 && (
					<div>{renderPopupMessages(popupMessage.page3)}</div>
				)}
				{currentPage === 4 && popupMessage.page4.length > 0 && (
					<div>{renderPopupMessages(popupMessage.page4)}</div>
				)}
				<button
					onClick={() =>
						setCurrentPage((prevPage) => (prevPage === 4 ? 1 : prevPage + 1))
					}
					className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
				>
					{currentPage === 4 ? "First Page" : "Next Page"}
				</button>
			</Modal>

			<div className="flex flex-wrap gap-2 mb-4">
				{stores.map((store) => (
					<button
						key={store._id}
						className={`px-4 py-2 rounded ${
							selectedStore && selectedStore._id === store._id
								? "bg-green-500 text-white"
								: "bg-orange-400 text-white"
						}`}
						onClick={() => handleStoreSelect(store)}
					>
						{store.Nama_Toko}
					</button>
				))}
			</div>

			<ActionButtons
				setIsAddModalOpen={setIsAddModalOpen}
				setIsCardModalOpen={setIsCardModalOpen}
				setIsUpdate={setIsUpdate}
				setIsscrape={setIsscrape}
				setIsAllDataModalOpen={setIsAllDataModalOpen}
			/>

			<ProductTable
				products={filteredProducts}
				showModal={(product) => {
					setSelectedProduct(product);
					setIsModalOpen(true);
				}}
				deleteProduct={deleteProduct}
			/>

			<ProductModal
				isModalOpen={isModalOpen}
				selectedProduct={selectedProduct}
				setSelectedProduct={setSelectedProduct}
				updateProduct={updateProduct}
				setIsModalOpen={setIsModalOpen}
			/>

			<PriceIncreaseModal
				isCardModalOpen={isCardModalOpen}
				setIsCardModalOpen={setIsCardModalOpen}
				selectedStore={selectedStore}
				priceIncreases={priceIncreases}
				handlePriceIncreaseChange={handlePriceIncreaseChange}
				applyPriceIncreases={applyPriceIncreases}
				setPriceIncreases={setPriceIncreases}
			/>

			<Update
				isOpen={isUpdate}
				onClose={() => setIsUpdate(false)}
				selectedStore={selectedStore}
			/>
			<Scrape
				isOpen={isscrape}
				onClose={() => setIsscrape(false)}
				selectedStore={selectedStore}
			/>
			<UpdateAllDataModal
				isOpen={isAllDataModalOpen}
				onClose={() => setIsAllDataModalOpen(false)}
				selectedStore={selectedStore}
			/>

			<div className="flex justify-between mt-4">
				<button
					onClick={handlePreviousPage}
					disabled={currentPage === 1}
					className="px-4 py-2 bg-gray-500 text-white rounded disabled:opacity-50"
				>
					Previous
				</button>
				<span>
					Page {currentPage} of {totalPages}
				</span>
				<button
					onClick={handleNextPage}
					disabled={currentPage === totalPages}
					className="px-4 py-2 bg-gray-500 text-white rounded disabled:opacity-50"
				>
					Next
				</button>
			</div>
		</div>
	);
}

export default DataProduct;
