import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal";

function Update({ isOpen, onClose, selectedStore }) {
	const [updateInterval, setUpdateInterval] = useState("");
	const [updateOptions, setUpdateOptions] = useState([]);

	const handleOptionChange = (option, setOptions) => {
		setOptions((prev) =>
			prev.includes(option)
				? prev.filter((o) => o !== option)
				: [...prev, option]
		);
	};

	const handleSave = async () => {
		if (!selectedStore) {
			alert("Please select a store.");
			return;
		}

		const tokoId = selectedStore._id;
		const userId = localStorage.getItem("id");
		const token = localStorage.getItem("token");

		const apiCalls = updateOptions.map((option) => {
			let url;
			switch (option) {
				case "Stok Kosong":
					url = `${process.env.REACT_APP_API_BASE_URL}/product-toko-update/${tokoId}/${userId}/stokkosong`;
					break;
				case "Stok yang berubah":
					url = `${process.env.REACT_APP_API_BASE_URL}/product-toko-update/${tokoId}/${userId}/stokberubah`;
					break;
				case "Harga berubah":
					url = `${process.env.REACT_APP_API_BASE_URL}/product-toko-update/${tokoId}/${userId}/hargaberubah`;
					break;
				case "Semua":
					url = `${process.env.REACT_APP_API_BASE_URL}/product-toko-update/${tokoId}/${userId}/semua`;
					break;
				default:
					return null;
			}

			return axios.post(
				url,
				{
					interval: updateInterval,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
		});

		try {
			const responses = await Promise.all(apiCalls);
			responses.forEach((response) => {
				if (response.data && response.data.message) {
					alert(response.data.message);
				}
			});
			console.log("Settings saved:", {
				updateInterval,
				updateOptions,
				selectedStore,
			});
			alert("Pengaturan berhasil disimpan dan dijalankan!");
			onClose();
		} catch (error) {
			console.error("Error saving settings:", error);
			alert("Terjadi kesalahan saat menyimpan pengaturan.");
		}
	};

	return (
		<Modal
			show={isOpen}
			onClose={onClose}
		>
			<h2 className="text-lg font-bold mb-4 text-center">
				{selectedStore ? selectedStore.Nama_Toko : "Select a Store"}
			</h2>

			<fieldset className="mb-4">
				<legend className="font-semibold">
					Product yang ingin diperbarui:
				</legend>
				{["Stok Kosong", "Stok yang berubah", "Harga berubah", "Semua"].map(
					(option) => (
						<label
							key={option}
							className="flex items-center mt-2"
						>
							<input
								type="checkbox"
								className="mr-2"
								checked={updateOptions.includes(option)}
								onChange={() => handleOptionChange(option, setUpdateOptions)}
							/>
							<span>{option}</span>
						</label>
					)
				)}
			</fieldset>

			<fieldset className="mt-6">
				<legend className="font-semibold">Product diupdate Setiap:</legend>
				{["Sekali", "3 Hari", "1 Minggu", "1 Bulan"].map((option) => (
					<label
						key={option}
						className="flex items-center mt-2"
					>
						<input
							type="radio"
							name="updateInterval"
							className="mr-2"
							checked={updateInterval === option}
							onChange={() => setUpdateInterval(option)}
						/>
						<span>{option}</span>
					</label>
				))}
			</fieldset>

			<button
				className="bg-blue-500 text-white px-4 py-2 rounded w-full mt-4"
				onClick={handleSave}
			>
				Save dan Jalankan
			</button>
		</Modal>
	);
}

export default Update;
