import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import Dashboard from "../components/Admin/Dashboard/Dashboard";

function Admin() {
	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-1 p-6 bg-gray-50">
				<Dashboard />
			</div>
		</div>
	);
}

export default Admin;
