import React, { useState, useEffect } from "react";

function DetailPesanan() {
	const [bigSellers, setBigSellers] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [currentSeller, setCurrentSeller] = useState({
		name: "",
		cookie: "",
		MUC_Token: "",
	});
	const [isEditing, setIsEditing] = useState(false);
	const [editIndex, setEditIndex] = useState(null);
	const [user, setUser] = useState({ Username: "", Password: "" });

	const token = localStorage.getItem("token");
	const userId = localStorage.getItem("id");

	useEffect(() => {
		fetchBigSellers();
		fetchUserData();
	}, []);

	const fetchBigSellers = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/big-sellers`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setBigSellers(data);
		} catch (error) {
			console.error("Failed to fetch big sellers:", error);
		}
	};

	const fetchUserData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error("Failed to fetch user data");
			}

			const data = await response.json();
			setUser(data);
		} catch (error) {
			console.error("Failed to fetch user data:", error);
		}
	};

	const handleUserChange = (e) => {
		const { name, value } = e.target;
		setUser({ ...user, [name]: value });
	};

	const handleUserSave = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(user),
				}
			);
			if (!response.ok) {
				throw new Error("Failed to update user data");
			}
			alert("User data updated successfully");
		} catch (error) {
			console.error("Failed to update user data:", error);
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/big-sellers/${id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) {
				throw new Error("Failed to delete big seller");
			}
			setBigSellers(bigSellers.filter((seller) => seller._id !== id));
		} catch (error) {
			console.error("Failed to delete big seller:", error);
		}
	};

	const handleEdit = (index) => {
		setCurrentSeller(bigSellers[index]);
		setIsEditing(true);
		setEditIndex(index);
		setModalOpen(true);
	};

	const handleAdd = () => {
		setCurrentSeller({ name: "", cookie: "", MUC_Token: "" });
		setIsEditing(false);
		setModalOpen(true);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCurrentSeller({ ...currentSeller, [name]: value });
	};

	const handleSubmit = async () => {
		try {
			if (isEditing) {
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/big-sellers/${bigSellers[editIndex]._id}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(currentSeller),
					}
				);
				if (!response.ok) {
					throw new Error("Failed to update big seller");
				}
				const updatedSeller = await response.json();
				setBigSellers(
					bigSellers.map((seller, index) =>
						index === editIndex ? updatedSeller : seller
					)
				);
			} else {
				const response = await fetch(
					`${process.env.REACT_APP_API_BASE_URL}/big-sellers`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(currentSeller),
					}
				);
				if (!response.ok) {
					throw new Error("Failed to add big seller");
				}
				const newSeller = await response.json();
				setBigSellers([...bigSellers, newSeller]);
			}
			setModalOpen(false);
		} catch (error) {
			console.error("Failed to submit big seller:", error);
		}
	};

	return (
		<div className="bg-gray-100 min-h-screen flex flex-col">
			<div className="flex-grow w-full bg-white p-4 md:p-10 overflow-auto">
				<h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-8">
					Detail Akun
				</h1>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
					<div>
						<label className="block text-base md:text-lg font-medium">
							Username
						</label>
						<input
							type="text"
							name="Username"
							className="mt-2 block w-full border border-gray-300 rounded-md p-2.5"
							value={user.Username}
							onChange={handleUserChange}
						/>
					</div>
					<div>
						<label className="block text-base md:text-lg font-medium">
							Password Baru
						</label>
						<input
							type="text"
							name="Password"
							className="mt-2 block w-full border border-gray-300 rounded-md p-2.5"
							onChange={handleUserChange}
						/>
					</div>
				</div>
				<button
					onClick={handleUserSave}
					className="bg-blue-500 hover:bg-blue-600 transition text-white py-2 px-4 rounded mt-4"
				>
					Save User
				</button>

				<h2 className="text-xl md:text-2xl font-bold mt-8 mb-4">Big Sellers</h2>
				<button
					onClick={handleAdd}
					className="bg-green-500 hover:bg-green-600 transition text-white py-2 px-4 rounded mb-4"
				>
					Add Seller
				</button>
				<div className="overflow-x-auto">
					<table className="min-w-full bg-white border border-gray-300">
						<thead>
							<tr className="bg-gray-200">
								<th className="py-3 px-4 border-b text-left">Name</th>
								<th className="py-3 px-4 border-b text-left">Cookie</th>
								<th className="py-3 px-4 border-b text-left">Token</th>
								<th className="py-3 px-4 border-b text-left">Actions</th>
							</tr>
						</thead>
						<tbody>
							{bigSellers.map((seller, index) => (
								<tr
									key={seller._id}
									className="hover:bg-gray-100 transition"
								>
									<td className="py-3 px-4 border-b">{seller.name}</td>
									<td className="py-3 px-4 border-b whitespace-nowrap overflow-hidden overflow-ellipsis max-w-xs">
										{seller.cookie}
									</td>
									<td className="py-3 px-4 border-b whitespace-nowrap overflow-hidden overflow-ellipsis max-w-xs">
										{seller.MUC_Token}
									</td>
									<td className="py-3 px-4 border-b">
										<button
											onClick={() => handleEdit(index)}
											className="bg-yellow-500 hover:bg-yellow-600 transition text-white py-1 px-2 rounded mr-2"
										>
											Edit
										</button>
										<button
											onClick={() => handleDelete(seller._id)}
											className="bg-red-500 hover:bg-red-600 transition text-white py-1 px-2 rounded"
										>
											Delete
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div className="flex flex-col md:flex-row justify-between mt-4 md:mt-8">
					<button
						className="bg-red-500 hover:bg-red-600 transition text-white py-2 md:py-3 px-4 md:px-6 rounded text-base md:text-lg mb-2 md:mb-0"
						onClick={() => {
							localStorage.removeItem("id");
							localStorage.removeItem("token");
							window.location.reload();
						}}
					>
						Logout
					</button>
				</div>
			</div>

			{modalOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/3">
						<h2 className="text-xl font-bold mb-4">
							{isEditing ? "Edit Seller" : "Add Seller"}
						</h2>
						<div className="mb-4">
							<label className="block text-base font-medium">Name</label>
							<input
								type="text"
								name="name"
								value={currentSeller.name}
								onChange={handleChange}
								className="mt-2 block w-full border border-gray-300 rounded-md p-2.5"
							/>
						</div>
						<div className="mb-4">
							<label className="block text-base font-medium">Cookie</label>
							<input
								type="text"
								name="cookie"
								value={currentSeller.cookie}
								onChange={handleChange}
								className="mt-2 block w-full border border-gray-300 rounded-md p-2.5"
							/>
						</div>
						<div className="mb-4">
							<label className="block text-base font-medium">Token</label>
							<input
								type="text"
								name="MUC_Token"
								value={currentSeller.MUC_Token}
								onChange={handleChange}
								className="mt-2 block w-full border border-gray-300 rounded-md p-2.5"
							/>
						</div>
						<div className="flex justify-end">
							<button
								onClick={() => setModalOpen(false)}
								className="bg-gray-500 hover:bg-gray-600 transition text-white py-2 px-4 rounded mr-2"
							>
								Cancel
							</button>
							<button
								onClick={handleSubmit}
								className="bg-blue-500 hover:bg-blue-600 transition text-white py-2 px-4 rounded"
							>
								Save
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default DetailPesanan;
