import React, { useState, useEffect } from "react";
import Modal from "./Modal";

function ProductModal({
	isModalOpen,
	isAddModalOpen,
	selectedProduct = {},
	newProduct = {},
	setSelectedProduct,
	setNewProduct,
	updateProduct,
	addProduct,
	setIsModalOpen,
	setIsAddModalOpen,
}) {
	const flattenArray = (arr) => (Array.isArray(arr) ? arr.flat() : []);

	useEffect(() => {
		if (selectedProduct?.Varian_Shopee) {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Shopee: flattenArray(selectedProduct.Varian_Shopee),
			}));
		}
		if (selectedProduct?.Varian_Suplier) {
			setSelectedProduct((prev) => ({
				...prev,
				Varian_Suplier: flattenArray(selectedProduct.Varian_Suplier),
			}));
		}
	}, [selectedProduct]);

	const handleAddVariantDetail = (type) => {
		const newVariant = { Nama: "", Harga: 0, Stok: 0, ID: "" };
		const variantKey = type === "shopee" ? "Varian_Shopee" : "Varian_Suplier";
		const updatedVariants = selectedProduct[variantKey]
			? [...selectedProduct[variantKey]]
			: [];
		updatedVariants.push(newVariant);
		setSelectedProduct({
			...selectedProduct,
			[variantKey]: updatedVariants,
		});
	};

	const handleRemoveVariant = (type, index) => {
		const variantKey = type === "shopee" ? "Varian_Shopee" : "Varian_Suplier";
		const updatedVariants = selectedProduct[variantKey]
			? [...selectedProduct[variantKey]]
			: [];
		updatedVariants.splice(index, 1);
		setSelectedProduct({
			...selectedProduct,
			[variantKey]: updatedVariants,
		});
	};

	const handleAddNewVariant = (type) => {
		const newVariant = { Nama: "", Harga: 0, Stok: 0, ID: "" };
		const variantKey = type === "shopee" ? "Varian_Shopee" : "Varian_Suplier";
		const updatedVariants = newProduct[variantKey]
			? [...newProduct[variantKey]]
			: [];
		updatedVariants.push(newVariant);
		setNewProduct({
			...newProduct,
			[variantKey]: updatedVariants,
		});
	};

	const removeVariant = (type, index) => {
		const variantKey = type === "shopee" ? "Varian_Shopee" : "Varian_Suplier";
		const updatedVariants = newProduct[variantKey]
			? [...newProduct[variantKey]]
			: [];
		updatedVariants.splice(index, 1);
		setNewProduct({
			...newProduct,
			[variantKey]: updatedVariants,
		});
	};

	return (
		<>
			<Modal
				show={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			>
				{selectedProduct && (
					<div>
						<h2 className="text-lg font-bold mb-4">Product Details</h2>
						<div>
							{[
								{
									label: "ID Product",
									value: selectedProduct.Id_product,
									disabled: true,
								},
								{
									label: "Nama Product Shopee",
									value: selectedProduct.Nama_Product_Shopee,
									key: "Nama_Product_Shopee",
									disabled: true,
								},
								{
									label: "Nama Product Supplier",
									value: selectedProduct.Nama_Product_Supplier,
									key: "Nama_Product_Supplier",
								},
								{
									label: "Berat Product Shopee (kg)",
									value: selectedProduct.Berat_Product_Shopee,
									key: "Berat_Product_Shopee",
									type: "number",
								},
								{
									label: "Berat Product Suplier (kg)",
									value: selectedProduct.Berat_Product_Suplier,
									key: "Berat_Product_Suplier",
									type: "number",
								},
								// {
								// 	label: "Tanggal Update",
								// 	value: selectedProduct.Tanggal_Update,
								// 	key: "Tanggal_Update",
								// 	type: "date",
								// 	disabled: true,
								// },
								// {
								// 	label: "Tanggal Scrape",
								// 	value: selectedProduct.Tanggal_Scrape,
								// 	key: "Tanggal_Scrape",
								// 	type: "date",
								// 	disabled: true,
								// },
								{
									label: "Deskripsi Product Shopee",
									value: selectedProduct.Deskripsi_Product_Shopee,
									key: "Deskripsi_Product_Shopee",
									type: "textarea",
									disabled: true,
								},
								{
									label: "Deskripsi Product Suplier",
									value: selectedProduct.Deskripsi_Product_Suplier,
									key: "Deskripsi_Product_Suplier",
									type: "textarea",
								},
								{
									label: "URL",
									value: selectedProduct.URL,
									key: "URL",
									type: "url",
								},
								{
									label: "Varian Shopee 1",
									value: selectedProduct.Varian_Shopee_1,
									key: "Varian_Shopee_1",
									disabled: true,
								},
							].map((field, idx) => (
								<div
									key={idx}
									className="mb-2"
								>
									<label>{field.label}:</label>
									{field.type === "textarea" ? (
										<textarea
											value={field.value ?? ""}
											disabled={field.disabled}
											onChange={(e) =>
												setSelectedProduct({
													...selectedProduct,
													[field.key]: e.target.value,
												})
											}
											className="w-full p-2 border rounded"
										/>
									) : (
										<input
											type={field.type || "text"}
											value={field.value ?? ""}
											disabled={field.disabled}
											onChange={(e) =>
												setSelectedProduct({
													...selectedProduct,
													[field.key]: e.target.value,
												})
											}
											className="w-full p-2 border rounded"
										/>
									)}
								</div>
							))}

							{/* Varian Shopee */}
							<h3 className="font-semibold mt-4">Varian Shopee:</h3>
							<ul>
								{(selectedProduct?.Varian_Shopee || []).map(
									(variant, index) => (
										<li
											key={index}
											className="mb-2"
										>
											{["Nama", "Harga", "Stok"].map((attr, idx) => (
												<div
													key={idx}
													className="mb-1"
												>
													<label>{attr}:</label>
													<input
														type={attr === "Nama" ? "text" : "number"}
														value={variant[attr] ?? ""}
														disabled={true}
														onChange={(e) => {
															const updatedVariants = [
																...selectedProduct.Varian_Shopee,
															];
															updatedVariants[index] = {
																...updatedVariants[index],
																[attr]: e.target.value,
															};
															setSelectedProduct({
																...selectedProduct,
																Varian_Shopee: updatedVariants,
															});
														}}
														className="w-full p-2 border rounded"
													/>
												</div>
											))}
											<button
												className="bg-red-500 text-white px-2 rounded"
												onClick={() => handleRemoveVariant("shopee", index)}
											>
												Remove
											</button>
										</li>
									)
								)}
							</ul>
							{/* <button
								className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
								onClick={() => handleAddVariantDetail("shopee")}
							>
								Add Varian Shopee
							</button> */}

							{/* Varian Supplier */}
							<h3 className="font-semibold mt-4">Varian Supplier:</h3>
							<ul>
								{(selectedProduct?.Varian_Suplier || []).map(
									(variant, index) => (
										<li
											key={index}
											className="mb-2"
										>
											{["Nama", "Harga", "Stok"].map((attr, idx) => (
												<div
													key={idx}
													className="mb-1"
												>
													<label>{attr}:</label>
													<input
														type={attr === "Nama" ? "text" : "number"}
														value={variant[attr] ?? ""}
														onChange={(e) => {
															const updatedVariants = [
																...selectedProduct.Varian_Suplier,
															];
															updatedVariants[index] = {
																...updatedVariants[index],
																[attr]: e.target.value,
															};
															setSelectedProduct({
																...selectedProduct,
																Varian_Suplier: updatedVariants,
															});
														}}
														className="w-full p-2 border rounded"
													/>
												</div>
											))}
											<button
												className="bg-red-500 text-white px-2 rounded"
												onClick={() => handleRemoveVariant("suplier", index)}
											>
												Remove
											</button>
										</li>
									)
								)}
							</ul>
							<button
								className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
								onClick={() => handleAddVariantDetail("suplier")}
							>
								Add Varian Supplier
							</button>

							<button
								className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
								onClick={() => updateProduct(selectedProduct)}
							>
								Update Product
							</button>
						</div>
					</div>
				)}
			</Modal>

			<Modal
				show={isAddModalOpen}
				onClose={() => setIsAddModalOpen(false)}
			>
				<h2 className="text-lg font-bold mb-4">Add New Product</h2>
				{/* New Product Form */}
				{[
					{
						placeholder: "ID Product",
						value: newProduct.Id_product,
						key: "Id_product",
						type: "number",
						disabled: true,
					},
					{
						placeholder: "Nama Product Shopee",
						value: newProduct.Nama_Product_Shopee,
						key: "Nama_Product_Shopee",
						disabled: true,
					},
					{
						placeholder: "Nama Product Supplier",
						value: newProduct.Nama_Product_Supplier,
						key: "Nama_Product_Supplier",
					},
					{
						placeholder: "Berat Product Shopee",
						value: newProduct.Berat_Product_Shopee,
						key: "Berat_Product_Shopee",
						type: "number",
					},
					{
						placeholder: "Berat Product Suplier",
						value: newProduct.Berat_Product_Suplier,
						key: "Berat_Product_Suplier",
						type: "number",
					},
					// {
					// 	placeholder: "Tanggal Update",
					// 	value: newProduct.Tanggal_Update,
					// 	key: "Tanggal_Update",
					// 	type: "date",
					// 	disabled: true,
					// },
					// {
					// 	placeholder: "Tanggal Scrape",
					// 	value: newProduct.Tanggal_Scrape,
					// 	key: "Tanggal_Scrape",
					// 	type: "date",
					// 	disabled: true,
					// },
					{
						placeholder: "Deskripsi Product Shopee",
						value: newProduct.Deskripsi_Product_Shopee,
						key: "Deskripsi_Product_Shopee",
						disabled: true,
					},
					{
						placeholder: "Deskripsi Product Suplier",
						value: newProduct.Deskripsi_Product_Suplier,
						key: "Deskripsi_Product_Suplier",
					},
					{
						placeholder: "URL",
						value: newProduct.URL,
						key: "URL",
						type: "url",
					},
					{
						placeholder: "Varian Shopee 1",
						value: newProduct.Varian_Shopee_1,
						key: "Varian_Shopee_1",
						disabled: true,
					},
					{
						placeholder: "Varian Shopee 2",
						value: newProduct.Varian_Shopee_2,
						key: "Varian_Shopee_2",
					},
				].map((field, idx) => (
					<div
						key={idx}
						className="mb-2"
					>
						<input
							type={field.type || "text"}
							placeholder={field.placeholder}
							value={field.value ?? ""}
							disabled={field.disabled}
							onChange={(e) =>
								setNewProduct({
									...newProduct,
									[field.key]: e.target.value,
								})
							}
							className="w-full p-2 border rounded"
						/>
					</div>
				))}

				{/* Varian Shopee */}
				<h3 className="font-semibold mt-4">Varian Shopee</h3>
				{(newProduct?.Varian_Shopee || []).map((variant, index) => (
					<div
						key={index}
						className="flex items-center mb-2"
					>
						{["Nama", "Harga", "Stok"].map((attr, idx) => (
							<input
								key={idx}
								type={attr === "Nama" ? "text" : "number"}
								placeholder={attr}
								value={variant[attr] ?? ""}
								disabled={true}
								onChange={(e) => {
									const updatedVariants = [...newProduct.Varian_Shopee];
									updatedVariants[index] = {
										...updatedVariants[index],
										[attr]: e.target.value,
									};
									setNewProduct({
										...newProduct,
										Varian_Shopee: updatedVariants,
									});
								}}
								className="w-full p-2 border rounded mr-2"
							/>
						))}
						<button
							onClick={() => removeVariant("shopee", index)}
							className="bg-red-500 text-white px-2 rounded"
						>
							Remove
						</button>
					</div>
				))}
				<button
					onClick={() => handleAddNewVariant("shopee")}
					className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
				>
					Add Varian Shopee
				</button>

				{/* Varian Supplier */}
				<h3 className="font-semibold mt-4">Varian Supplier</h3>
				{(newProduct?.Varian_Suplier || []).map((variant, index) => (
					<div
						key={index}
						className="flex items-center mb-2"
					>
						{["Nama", "Harga", "Stok"].map((attr, idx) => (
							<input
								key={idx}
								type={attr === "Nama" ? "text" : "number"}
								placeholder={attr}
								value={variant[attr] ?? ""}
								onChange={(e) => {
									const updatedVariants = [...newProduct.Varian_Suplier];
									updatedVariants[index] = {
										...updatedVariants[index],
										[attr]: e.target.value,
									};
									setNewProduct({
										...newProduct,
										Varian_Suplier: updatedVariants,
									});
								}}
								className="w-full p-2 border rounded mr-2"
							/>
						))}
						<button
							onClick={() => removeVariant("suplier", index)}
							className="bg-red-500 text-white px-2 rounded"
						>
							Remove
						</button>
					</div>
				))}
				<button
					onClick={() => handleAddNewVariant("suplier")}
					className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
				>
					Add Varian Supplier
				</button>

				<button
					className="bg-green-500 text-white px-4 py-2 rounded"
					onClick={addProduct}
				>
					Add Product
				</button>
			</Modal>
		</>
	);
}

export default ProductModal;
