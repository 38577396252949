import React from "react";

const FeaturesHeader = () => (
	<div className="flex items-center mb-8 mt-6">
		<h2 className="text-2xl font-bold bg-lime-300 inline-block px-3 py-1 rounded mr-4">
			Tentang Mitra
		</h2>
		<p>Penjelasan Tentang Mitra kami</p>
	</div>
);
const PartnerCard = ({ title, description, linkText }) => (
	<div className="flex-1 p-6 text-white">
		<p className="mb-4">{description}</p>
		<a
			href="#"
			className="text-lime-300 font-bold"
		>
			{linkText} &rarr;
		</a>
	</div>
);
const Features = () => (
	<section className="bg-white py-8">
		<div className="max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16">
			<FeaturesHeader />
			<div className="bg-black rounded-xl p-8 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
				<PartnerCard
					title="Dropshiper"
					description="Mitra dropshipper adalah mitra yang akan mengambil produk kami dan menyinkronkannya dengan toko Anda. Anda tidak perlu melakukan packing, karena tim kami akan menyiapkan seluruh pesanan Anda."
					linkText="Dropshiper"
				/>
				<div className="border-l border-gray-700 hidden md:block"></div>
				<PartnerCard
					title="Reseller"
					description="Reseller merupakan mitra yang berperan dalam mengimpor produk ke Indonesia dan meneruskannya ke gudang kami. Mereka bertanggung jawab memastikan bahwa produk-produk tersebut tiba dengan aman, sehingga dapat diproses lebih lanjut oleh tim kami."
					linkText="Reseller"
				/>
				<div className="border-l border-gray-700 hidden md:block"></div>
				<PartnerCard
					title="Partner"
					description="Mitra partner dapat mencakup berbagai bentuk kerja sama dalam bidang bisnis lainnya, seperti kolaborasi dengan penyedia layanan payment gateway, sponsor, investor, maupun penyedia jasa dan barang lainnya. Kerja sama ini bertujuan untuk memperkuat ekosistem bisnis."
					linkText="Partner"
				/>
			</div>
		</div>
	</section>
);

export default Features;
