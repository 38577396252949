import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Test from "../../asset/Images.png";

// Array for all featured products
const featuredProducts = [
	{
		name: "Syltherine",
		image: Test,
		description: "Stylish cafe chair",
		price: "Rp 2.500.000",
		originalPrice: "Rp 3.500.000",
		discount: "-30%",
		tag: null,
	},
	{
		name: "Lolito",
		image: Test,
		description: "Luxury big sofa",
		price: "Rp 7.000.000",
		originalPrice: "Rp 14.000.000",
		discount: "-50%",
		tag: null,
	},
	{
		name: "Respira",
		image: Test,
		description: "Outdoor bar table and stool",
		price: "Rp 500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Grifo",
		image: Test,
		description: "Night lamp",
		price: "Rp 1.500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Muggo",
		image: Test,
		description: "Small mug",
		price: "Rp 150.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
	{
		name: "Pingky",
		image: Test,
		description: "Cute bed set",
		price: "Rp 7.000.000",
		originalPrice: "Rp 14.000.000",
		discount: "-50%",
		tag: null,
	},
	{
		name: "Potty",
		image: Test,
		description: "Minimalist flower pot",
		price: "Rp 500.000",
		originalPrice: null,
		discount: null,
		tag: "New",
	},
];

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);

	const closePopup = () => {
		setSelectedProduct(null);
	};

	const handleConsultation = () => {
		navigate("/allert");
	};

	return (
		<>
			<div className="max-w-6xl mx-auto p-5">
				<h1 className="text-3xl font-bold mb-8 text-center text-gray-800 mt-20 mb-10">
					Semua Product
				</h1>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-8">
					{featuredProducts.map((product, index) => (
						<div
							className="relative bg-white shadow-lg hover:shadow-xl transition transform hover:scale-105 cursor-pointer overflow-hidden"
							key={index}
							onClick={() => setSelectedProduct(product)}
						>
							{product.discount && (
								<div className="absolute top-4 left-4 bg-red-500 text-white text-sm font-bold rounded-full w-12 h-12 flex items-center justify-center">
									{product.discount}
								</div>
							)}
							{product.tag && (
								<div className="absolute top-4 right-4 bg-indigo-600 text-white text-xs font-bold rounded-full py-1 px-3">
									{product.tag}
								</div>
							)}
							<div className="h-64">
								<img
									src={product.image}
									alt={`${product.name} Image`}
									className="w-full h-full object-cover"
								/>
							</div>
							<div className="p-4">
								<h3 className="text-lg font-semibold text-gray-800 mb-1">
									{product.name}
								</h3>
								<p className="text-gray-600 mb-2">{product.description}</p>
								<div className="text-gray-800 font-bold">
									<span className="text-lg">{product.price}</span>
									{product.originalPrice && (
										<span className="ml-2 line-through text-gray-500">
											{product.originalPrice}
										</span>
									)}
								</div>
							</div>
						</div>
					))}
				</div>

				{/* Popup Product */}
				{selectedProduct && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
						<div className="bg-white p-5 rounded-lg shadow-lg max-w-md w-full relative">
							<button
								className="absolute top-3 right-3 text-gray-600 text-xl"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="mb-4">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="w-full h-auto rounded"
								/>
							</div>
							<div className="text-left">
								<h3 className="text-xl font-semibold mb-2">
									{selectedProduct.name}
								</h3>
								<p className="mb-2">{selectedProduct.description}</p>
								<p className="font-bold mb-4">
									<strong>Price:</strong> {selectedProduct.price}
								</p>
								<button
									className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-500 transition"
									onClick={handleConsultation}
								>
									Reseller
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
