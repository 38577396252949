import React, { useState, useEffect } from "react";

function StoreSettings() {
	const [stores, setStores] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [bigSellers, setBigSellers] = useState([]);

	useEffect(() => {
		const fetchData = async (url, setter) => {
			try {
				const token = localStorage.getItem("token");
				const response = await fetch(url, {
					headers: { Authorization: `Bearer ${token}` },
				});
				if (!response.ok) throw new Error("Network response was not ok");
				const data = await response.json();
				setter(data || []);
			} catch (error) {
				console.error(`Failed to fetch data from ${url}:`, error);
				setter([]);
			}
		};

		fetchData(`${process.env.REACT_APP_API_BASE_URL}/toko`, setStores);
		fetchData(
			`${process.env.REACT_APP_API_BASE_URL}/big-sellers`,
			setBigSellers
		);

		const interval = setInterval(
			() => fetchData(`${process.env.REACT_APP_API_BASE_URL}/toko`, setStores),
			10000
		);
		return () => clearInterval(interval);
	}, []);

	const handleCardClick = (store) => {
		setSelectedStore(store);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setIsAddModalOpen(false);
	};

	const openAddModal = () => {
		setIsAddModalOpen(true);
	};

	const checkStoreStatuses = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko/check-status`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ stores }),
				}
			);
			if (!response.ok) throw new Error("Failed to check store statuses");
			const result = await response.json();
			alert("Store statuses updated successfully");
			setStores(result.results || []);
		} catch (error) {
			console.error("Error checking store statuses:", error);
			alert("Failed to check store statuses");
		}
	};

	return (
		<div className="p-6">
			<h1 className="text-2xl font-bold mb-6">Pengaturan Toko</h1>
			<button
				onClick={openAddModal}
				className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
			>
				Tambah Toko
			</button>
			<button
				onClick={checkStoreStatuses}
				className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
			>
				Cek Status Toko
			</button>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
				{stores.map((store) => (
					<StoreCard
						key={store._id}
						store={store}
						onCardClick={handleCardClick}
					/>
				))}
			</div>

			{isModalOpen && (
				<StoreModal
					isOpen={isModalOpen}
					onClose={closeModal}
					storeData={selectedStore}
					setStores={setStores}
					bigSellers={bigSellers}
				/>
			)}

			{isAddModalOpen && (
				<AddStoreModal
					isOpen={isAddModalOpen}
					onClose={closeModal}
					setStores={setStores}
					bigSellers={bigSellers}
				/>
			)}
		</div>
	);
}

function StoreCard({ store, onCardClick }) {
	const cardColor =
		store.Status === "Cookie aktif" ? "bg-green-200" : "bg-red-200";

	return (
		<div
			onClick={() => onCardClick(store)}
			className={`cursor-pointer p-4 rounded-lg shadow-lg ${cardColor}`}
		>
			<h3 className="text-xl font-semibold">{store.Nama_Toko}</h3>
			<p className="text-sm text-gray-700">{store.Status}</p>
		</div>
	);
}

function AddStoreModal({ isOpen, onClose, setStores, bigSellers }) {
	const [newStore, setNewStore] = useState({
		Cookie: "",
		Bigseller: "",
	});

	if (!isOpen) return null;

	const handleChange = (e) => {
		const { name, value } = e.target;
		setNewStore((prev) => ({ ...prev, [name]: value }));
	};

	const handleAdd = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(newStore),
				}
			);
			if (!response.ok) throw new Error("Failed to add store");
			const result = await response.json();
			alert("Store added successfully");
			setStores((prevStores) => [...prevStores, result.toko]);
			onClose();
		} catch (error) {
			console.error("Error adding store:", error);
		}
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="bg-white rounded-lg p-4 w-11/12 max-w-md mx-auto">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-bold">Tambah Toko</h2>
					<button
						onClick={onClose}
						className="text-red-500"
					>
						&times;
					</button>
				</div>
				<div className="space-y-4">
					{["Cookie", "Authorization"].map((field) => (
						<div key={field}>
							<label className="block text-sm font-medium text-gray-700">
								{field.replace("_", " ")}
							</label>
							<input
								type="text"
								name={field}
								value={newStore[field]}
								onChange={handleChange}
								className="w-full p-2 border rounded"
							/>
						</div>
					))}
					<div>
						<label className="block text-sm font-medium text-gray-700">
							Bigseller
						</label>
						<select
							name="Bigseller"
							value={newStore.Bigseller}
							onChange={handleChange}
							className="w-full p-2 border rounded"
						>
							<option
								value=""
								disabled
							>
								Pilih Bigseller
							</option>
							{bigSellers.map((seller) => (
								<option
									key={seller._id}
									value={seller._id}
								>
									{seller.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="flex justify-end mt-4 space-x-2">
					<button
						onClick={onClose}
						className="px-4 py-2 bg-gray-200 rounded"
					>
						Close
					</button>
					<button
						onClick={handleAdd}
						className="px-4 py-2 bg-green-500 text-white rounded"
					>
						Add
					</button>
				</div>
			</div>
		</div>
	);
}

function StoreModal({ isOpen, onClose, storeData, setStores, bigSellers }) {
	const [editableStore, setEditableStore] = useState(storeData);

	useEffect(() => {
		if (storeData) setEditableStore(storeData);
	}, [storeData]);

	if (!isOpen || !editableStore) return null;

	const handleChange = (e) => {
		const { name, value } = e.target;
		setEditableStore((prev) => ({
			...prev,
			[name]: name === "Bigseller" ? { _id: value } : value,
		}));
	};

	const handleSave = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko/${editableStore._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(editableStore),
				}
			);
			if (!response.ok) throw new Error("Failed to update store");
			const result = await response.json();
			alert(result.message);
			setStores((prevStores) =>
				prevStores.map((store) =>
					store._id === result.toko._id ? result.toko : store
				)
			);
			onClose();
		} catch (error) {
			console.error("Error updating store:", error);
		}
	};

	const handleDelete = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko/${editableStore._id}`,
				{
					method: "DELETE",
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			if (!response.ok) throw new Error("Failed to delete store");
			alert("Store deleted successfully");
			setStores((prevStores) =>
				prevStores.filter((store) => store._id !== editableStore._id)
			);
			onClose();
		} catch (error) {
			console.error("Error deleting store:", error);
		}
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="bg-white rounded-lg p-4 w-11/12 max-w-md mx-auto">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-bold">Data Toko</h2>
					<button
						onClick={onClose}
						className="text-red-500"
					>
						&times;
					</button>
				</div>
				<div className="space-y-4">
					<img
						src={editableStore.Foto?.path || ""}
						alt={editableStore.Nama_Toko}
						className="w-full h-auto mb-2"
					/>
					{["Nama_Toko", "Status", "Cookie", "Authorization"].map((field) => (
						<div key={field}>
							<label className="block text-sm font-medium text-gray-700">
								{field.replace("_", " ")}
							</label>
							<input
								type="text"
								name={field}
								value={editableStore[field] || ""}
								onChange={handleChange}
								className="w-full p-2 border rounded"
							/>
						</div>
					))}
					<div>
						<label className="block text-sm font-medium text-gray-700">
							Bigseller
						</label>
						<select
							name="Bigseller"
							value={editableStore.Bigseller?._id || ""}
							onChange={handleChange}
							className="w-full p-2 border rounded"
						>
							<option
								value=""
								disabled
							>
								Pilih Bigseller
							</option>
							{bigSellers.map((seller) => (
								<option
									key={seller._id}
									value={seller._id}
								>
									{seller.name}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="flex justify-end mt-4 space-x-2">
					<button
						onClick={handleDelete}
						className="px-4 py-2 bg-red-500 text-white rounded"
					>
						Delete
					</button>
					<button
						onClick={onClose}
						className="px-4 py-2 bg-gray-200 rounded"
					>
						Close
					</button>
					<button
						onClick={handleSave}
						className="px-4 py-2 bg-blue-500 text-white rounded"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}

export default StoreSettings;
