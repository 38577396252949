import React from "react";

function ActionButtons({
	setIsAddModalOpen,
	setIsCardModalOpen,
	setIsUpdate,
	setIsscrape,
	setIsAllDataModalOpen,
}) {
	return (
		<div className="flex gap-3 mb-4">
			<button
				className="bg-blue-500 text-white px-4 py-2 rounded"
				onClick={() => setIsUpdate(true)}
			>
				Update
			</button>
			<button
				className="bg-blue-500 text-white px-4 py-2 rounded ml-2"
				onClick={() => setIsscrape(true)}
			>
				Scrape
			</button>
			<button
				className="bg-blue-500 text-white px-4 py-2 rounded"
				onClick={() => setIsCardModalOpen(true)}
			>
				Setting Harga
			</button>
			<button
				className="bg-blue-500 text-white px-4 py-2 rounded"
				onClick={() => setIsAllDataModalOpen(true)}
			>
				Get Semua Data Toko dan Scrape semua data
			</button>
			{/* <button
				className="bg-green-500 text-white px-4 py-2 rounded"
				onClick={() => setIsAddModalOpen(true)}
			>
				Add New Product
			</button> */}
		</div>
	);
}

export default ActionButtons;
