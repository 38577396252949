import React from "react";
import Youtube from "../asset/Youtube.png";
import Twitter from "../asset/twitter.png";
import Tiktok from "../asset/tiktok.png";
import Instagram from "../asset/instagram.png";
import LinkedIn from "../asset/instagram.png";
import Facebook from "../asset/instagram.png";

function Footer() {
	const sendEmail = () => {
		const feedback = document.getElementById("feedback-input").value;
		const mailtoLink = `mailto:satuliniofficial@gmail.com?subject=Kritik%20dan%20Saran&body=${encodeURIComponent(
			feedback
		)}`;
		window.location.href = mailtoLink;
	};

	return (
		<footer className="bg-black text-gray-300 py-10">
			<div className="max-w-[1440px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 px-4 md:px-8 lg:px-16">
				<div className="text-left">
					<h4 className="text-lg text-black font-semibold bg-lime-300 inline-block px-3 py-1 rounded mb-4">
						Kontak Kami
					</h4>
					<p className="mb-5 text-white">
						Email: Pinterjualan@gmail.com
						<br />
						Phone: -
						<br />
						Cengkareng, Jakarta Barat
						<br />
						Indonesia
					</p>
					<div className="flex items-center gap-4 mb-4">
						<a href="#">
							<img
								src={LinkedIn}
								alt="LinkedIn"
								className="w-8 h-8"
							/>
						</a>
						<a href="#">
							<img
								src={Facebook}
								alt="Facebook"
								className="w-8 h-8"
							/>
						</a>
						<a href="#">
							<img
								src={Twitter}
								alt="Twitter"
								className="w-8 h-8"
							/>
						</a>
					</div>
				</div>

				<div className="text-left">
					<h4 className="text-lg font-semibold mb-4 text-white">
						Kritik dan saran
					</h4>
					<form className="flex flex-col space-y-3">
						<input
							type="text"
							id="feedback-input"
							placeholder="Masukan kritik singkat"
							className="p-2 border border-gray-700 rounded"
						/>
						<button
							type="button"
							className="bg-lime-300 text-black py-2 px-4 rounded hover:bg-indigo-600 transition"
							onClick={sendEmail}
						>
							Send
						</button>
					</form>
				</div>
			</div>
			<hr className="my-8 border-gray-700" />
			<div className="text-center text-sm text-white">
				&copy; 2023 Pinterjualan. All Rights Reserved.{" "}
				<a
					href="#"
					className="text-gray-400 hover:underline"
				>
					Privacy Policy
				</a>
			</div>
		</footer>
	);
}

export default Footer;
