import React from "react";
import HeroImage from "../../asset/Herocontent.png";
import Shopee from "../../asset/Shopee.png";
import Tokopedia from "../../asset/Tokopedia.png";
import Lazada from "../../asset/Lazada.png";
import TiktokShop from "../../asset/Tiktok.png";

const marketplace = [
	{
		id: 1,
		name: "Shopee",
		icon: Shopee,
	},
	{
		id: 2,
		name: "Tokopedia",
		icon: Tokopedia,
	},
	{
		id: 3,
		name: "Lazada",
		icon: Lazada,
	},
	{
		id: 4,
		name: "TikTok Shop",
		icon: TiktokShop,
	},
];

function HeroSection() {
	return (
		<section className="bg-white pt-20 pb-8">
			{" "}
			{/* Ubah min-h-screen menjadi padding yang lebih sesuai */}
			<div className="max-w-[1440px] mx-auto px-4 md:px-8 lg:px-16">
				<div className="grid md:grid-cols-2 gap-8 items-center pt-20">
					{/* Left Content */}
					<div className="space-y-6">
						<h1 className="text-4xl md:text-5xl lg:text-6xl text-left font-bold text-black leading-tight">
							Jualan Jadi Mudah dan Efektif Bersama Pinterjualan!
						</h1>
						<p className="text-gray-600 text-lg md:text-xl text-left">
							Platform dropship dan reseller yang mendukung merek lokal serta
							impor dan ekspor, terintegrasi dengan fitur Kecerdasan Buatan dan
							Blockchain.
						</p>

						<div className="flex justify-start">
							<button className="bg-black text-white px-8 py-3 rounded-lg font-medium hover:bg-gray-800 transition-all duration-300">
								Daftar Mitra
							</button>
						</div>

						{/* Marketplace Icons */}
						<div className="bg-[#F3F3F3] p-8 rounded-xl border-2 border-black shadow-[0_8px_30px_rgb(0,0,0,0.12)] relative before:absolute before:inset-0 before:z-0 before:border-2 before:border-black before:rounded-xl before:translate-x-2 before:translate-y-2">
							<div className="relative z-10">
								{" "}
								{/* Wrapper untuk konten agar di atas shadow */}
								<p className="text-xl text-black-500 mb-6">
									Terintegrasi dengan:
								</p>
								<div className="grid grid-cols-4 gap-8 items-center">
									{marketplace.map((item) => (
										<img
											key={item.id}
											src={item.icon}
											alt={item.name}
											className="h-20 w-auto object-contain hover:scale-110 transition-transform duration-300"
										/>
									))}
								</div>
							</div>
						</div>
					</div>

					{/* Right Content - Illustration */}
					<div className="hidden md:block">
						<img
							src={HeroImage}
							alt="Hero Illustration"
							className="w-full h-auto object-cover"
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HeroSection;
