import React, { useState } from "react";
import Modal from "./Modal";

function UpdateAllDataModal({ isOpen, onClose, selectedStore }) {
	const [allDataInterval, setAllDataInterval] = useState("");
	const [allDataOptions, setAllDataOptions] = useState([]);
	const [startPage, setStartPage] = useState("");
	const [endPage, setEndPage] = useState("");

	const handleOptionChange = (option, setOptions) => {
		setOptions((prev) =>
			prev.includes(option)
				? prev.filter((o) => o !== option)
				: [...prev, option]
		);
	};

	const handleSave = async () => {
		if (allDataInterval < 0) {
			alert("Interval tidak boleh negatif");
			return;
		}

		console.log("All data settings saved:", {
			allDataInterval,
			allDataOptions,
			selectedStore,
			startPage,
			endPage,
		});

		if (selectedStore && startPage && endPage) {
			const token = localStorage.getItem("token");
			const url = `${process.env.REACT_APP_API_BASE_URL}/productlist/${selectedStore._id}/${startPage}/${endPage}`;

			try {
				const response = await fetch(url, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				console.log("API Response:", data);
				alert("Pengaturan semua data berhasil disimpan dan API dipanggil!");
			} catch (error) {
				console.error("Error fetching data:", error);
				alert("Terjadi kesalahan saat memanggil API.");
			}
		} else {
			alert("Pastikan semua input telah diisi.");
		}

		onClose();
	};

	return (
		<Modal
			show={isOpen}
			onClose={onClose}
		>
			<h2 className="text-lg font-bold mb-4 text-center">
				{selectedStore ? selectedStore.Nama_Toko : "Select a Store"}
			</h2>

			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700">
					Start Page
				</label>
				<input
					type="number"
					className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
					placeholder="Enter start page"
					value={startPage}
					onChange={(e) => setStartPage(e.target.value)}
				/>
			</div>

			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700">
					End Page
				</label>
				<input
					type="number"
					className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
					placeholder="Enter end page"
					value={endPage}
					onChange={(e) => setEndPage(e.target.value)}
				/>
			</div>

			<button
				className="bg-blue-500 text-white px-4 py-2 rounded w-full mt-4"
				onClick={handleSave}
			>
				Save dan Jalankan
			</button>
		</Modal>
	);
}

export default UpdateAllDataModal;
