import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import useTitleUpdater from "./components/TitleUpdater";

// Import page components
import Home from "./Pages/Home.js";
import Product from "./Pages/Product.js";
import Login from "./Pages/Login.js";
import Allert from "./Pages/Allert.js";
import Fitur from "./Pages/Fitur.js";
import Admin from "./Pages/Admin.js";
import AdminStore from "./Pages/Admin-store.js";
import AdminProduct from "./Pages/Admin-product.js";
import AdminProductDetail from "./Pages/Admin-product_Detail.js";
import Pesan from "./Pages/Admin-pesan.js";
import Pesanan from "./Pages/Admin-pesanan.js";
import Setting from "./Pages/Admin-setting.js";
import Keuangan from "./Pages/Admin-keuangan.js";

// PrivateRoute component
const PrivateRoute = ({ element }) => {
	const isAuthenticated = !!localStorage.getItem("token");
	return isAuthenticated ? element : <Navigate to="/login" />;
};

const AdminRoute = ({ element }) => {
	useTitleUpdater(); // Use the hook here to update the title
	return element;
};

function App() {
	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={<Navigate to="/home" />}
				/>
				<Route
					path="/home"
					element={<Home />}
				/>
				<Route
					path="/product"
					element={<Product />}
				/>
				<Route
					path="/fitur"
					element={<Fitur />}
				/>
				<Route
					path="/login"
					element={<Login />}
				/>
				<Route
					path="/allert"
					element={<Allert />}
				/>

				<Route
					path="/admin"
					element={
						<PrivateRoute element={<AdminRoute element={<Admin />} />} />
					}
				/>
				<Route
					path="/store-settings"
					element={
						<PrivateRoute element={<AdminRoute element={<AdminStore />} />} />
					}
				/>
				<Route
					path="/data-products"
					element={
						<PrivateRoute element={<AdminRoute element={<AdminProduct />} />} />
					}
				/>
				<Route
					path="/data-products1"
					element={
						<PrivateRoute
							element={<AdminRoute element={<AdminProductDetail />} />}
						/>
					}
				/>
				<Route
					path="/Pesan"
					element={
						<PrivateRoute element={<AdminRoute element={<Pesan />} />} />
					}
				/>
				<Route
					path="/sales"
					element={
						<PrivateRoute element={<AdminRoute element={<Pesanan />} />} />
					}
				/>
				<Route
					path="/settings"
					element={
						<PrivateRoute element={<AdminRoute element={<Setting />} />} />
					}
				/>
				<Route
					path="/finance"
					element={
						<PrivateRoute element={<AdminRoute element={<Keuangan />} />} />
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
