import React, { useState } from "react";
import Modal from "./Modal";

function Scrape({ isOpen, onClose, selectedStore }) {
	const [scrapePriceInterval, setScrapePriceInterval] = useState("Sekali");

	const handleSave = async () => {
		const token = localStorage.getItem("token");
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/scrape/${selectedStore._id}`,
				{
					method: "POST", // Use POST to send data
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ interval: scrapePriceInterval }),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to schedule scraping");
			}

			alert("Scraping scheduled successfully!");
		} catch (error) {
			console.error("Error scheduling scraping:", error);
			alert("There was an error scheduling the scraping.");
		}

		onClose();
	};

	return (
		<Modal
			show={isOpen}
			onClose={onClose}
		>
			<h2 className="text-lg font-bold mb-4 text-center">
				{selectedStore ? selectedStore.Nama_Toko : "Select a Store"}
			</h2>

			<fieldset className="mt-6">
				<legend className="font-semibold">discrape Setiap:</legend>
				{["Sekali", "3 Hari", "1 Minggu", "1 Bulan"].map((option) => (
					<label
						key={option}
						className="flex items-center mt-2"
					>
						<input
							type="radio"
							name="scrapePriceInterval"
							className="mr-2"
							checked={scrapePriceInterval === option}
							onChange={() => setScrapePriceInterval(option)}
						/>
						<span>{option}</span>
					</label>
				))}
			</fieldset>

			<button
				className="bg-blue-500 text-white px-4 py-2 rounded w-full mt-4"
				onClick={handleSave}
			>
				Save dan Jalankan
			</button>
		</Modal>
	);
}

export default Scrape;
