import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginPage() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/login`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ Username: username, password }),
				}
			);

			const data = await response.json();
			if (response.ok) {
				localStorage.setItem("token", data.token);
				localStorage.setItem("id", data.user._id);
				setSuccessMessage("Login successful!");
				window.location.href = "/admin";
			}
		} catch (error) {
			setErrorMessage("Error: " + error.message);
		}
	};

	return (
		<div className="flex justify-center items-center h-screen bg-gray-100 p-5">
			<div className="bg-white p-10 mt-20 rounded-lg shadow-lg w-full max-w-md flex flex-col items-center">
				<h2 className="text-center mb-5 text-2xl font-bold">Login</h2>
				<form
					onSubmit={handleLogin}
					className="w-full flex flex-col"
				>
					<div className="flex flex-col mb-5">
						<label
							htmlFor="username"
							className="mb-2 font-bold"
						>
							username
						</label>
						<input
							type="text"
							id="username"
							name="username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
							className="w-full p-3 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="flex flex-col mb-5">
						<label
							htmlFor="password"
							className="mb-2 font-bold"
						>
							Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							className="w-full p-3 border border-gray-300 rounded-md"
						/>
					</div>
					<button
						type="submit"
						className="w-full bg-indigo-600 text-white p-3 rounded-md font-semibold hover:bg-indigo-500 transition-colors"
					>
						Login
					</button>
				</form>
				{errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
				{successMessage && (
					<p className="mt-4 text-green-500">{successMessage}</p>
				)}
				<button
					className="mt-5 w-full bg-gray-200 text-gray-800 p-3 rounded-md hover:bg-gray-300 transition-colors"
					onClick={() => navigate(-1)}
				>
					Back
				</button>
			</div>
		</div>
	);
}

export default LoginPage;
