import React, { useState, useEffect } from "react";

const DataKeuangan = () => {
	const [pemasukan, setPemasukan] = useState([]);
	const [pengeluaran, setPengeluaran] = useState([]);
	const [financeSummary, setFinanceSummary] = useState({
		total_danatertahan: 0,
		total_danacair: 0,
		total_keuntungan: 0,
		ratarata_total_keuntungan: 0,
	});
	const [editData, setEditData] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const token = localStorage.getItem("token");

	// Fetch data from backend
	const fetchKeuangan = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/keuangantotal`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) throw new Error("Failed to fetch data");
			const data = await response.json();
			setPemasukan(data.filter((item) => item.Jenis === "Pemasukan"));
			setPengeluaran(data.filter((item) => item.Jenis === "Pengeluaran"));
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	// Fetch finance summary
	const fetchFinanceSummary = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/api/finance-summary`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) throw new Error("Failed to fetch finance summary");
			const data = await response.json();
			setFinanceSummary(data);
		} catch (error) {
			console.error("Error fetching finance summary:", error);
		}
	};

	useEffect(() => {
		fetchKeuangan();
		fetchFinanceSummary();
	}, []);

	// Calculate total KAS
	const totalPemasukan = pemasukan.reduce((acc, curr) => acc + curr.Jumlah, 0);
	const totalPengeluaran = pengeluaran.reduce(
		(acc, curr) => acc + curr.Jumlah,
		0
	);
	const totalbiaya = totalPemasukan - totalPengeluaran;
	const totalPenjualan =
		financeSummary.total_danacair - financeSummary.total_danatertahan;
	const adjustedKAS = totalbiaya + totalPenjualan;

	const addOrEditData = async () => {
		try {
			const method = editData.id ? "PUT" : "POST";
			const url = `${process.env.REACT_APP_API_BASE_URL}/keuangantotal${
				editData.id ? `/${editData.id}` : ""
			}`;
			const response = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(editData),
			});
			if (!response.ok) throw new Error("Failed to save data");
			await fetchKeuangan();
			closeModal();
		} catch (error) {
			console.error("Error saving data:", error);
		}
	};

	// Delete data
	const deleteKeuangan = async (id) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/keuangantotal/${id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) throw new Error("Failed to delete data");
			await fetchKeuangan();
		} catch (error) {
			console.error("Error deleting data:", error);
		}
	};

	// Open modal for editing or adding
	const openModal = (item = null) => {
		if (item) {
			setEditData(item);
		} else {
			setEditData({
				Keterangan: "",
				Jumlah: 0,
				Tanggal: "",
				Jenis: "Pemasukan",
				PenanggungJawab: "",
			});
		}
		setIsModalOpen(true);
	};

	// Close modal
	const closeModal = () => {
		setEditData(null);
		setIsModalOpen(false);
	};

	// Download data function
	const downloadData = () => {
		const data = { pemasukan, pengeluaran };
		const blob = new Blob([JSON.stringify(data, null, 2)], {
			type: "application/json",
		});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "data-keuangan.json";
		a.click();
	};

	return (
		<div className="flex flex-wrap justify-left p-4">
			{/* Total KAS */}
			<div className="w-full p-2">
				{/* <h2 className="text-xl font-semibold mb-4">Total KAS</h2>
				<p className="text-2xl mb-4">Rp {totalKAS.toLocaleString()}</p> */}
				<h2 className="text-xl font-semibold mb-4">TOTAL KAS</h2>
				<p className="text-2xl mb-4">Rp {adjustedKAS.toLocaleString()}</p>
			</div>

			{/* Card Pemasukan */}
			<div className="w-full md:w-1/2 p-2">
				<div className="bg-white shadow-lg rounded-lg p-6">
					<h2 className="text-xl font-semibold mb-4">
						Jumlah Pemasukan Diluar Pesanan
					</h2>
					<p className="text-2xl text-green-500 mb-4">
						Rp {totalPemasukan.toLocaleString()}
					</p>
					<button
						onClick={() => openModal()}
						className="bg-green-500 text-white p-1 rounded mb-4"
					>
						Tambah
					</button>
					<table className="min-w-full bg-white border border-gray-200">
						<thead>
							<tr className="bg-gray-100">
								<th className="py-2 px-4 border-b text-left">Keterangan</th>
								<th className="py-2 px-4 border-b text-left">
									Jumlah Pemasukan
								</th>
								<th className="py-2 px-4 border-b text-left">Tanggal</th>
								<th className="py-2 px-4 border-b text-left">Aksi</th>
							</tr>
						</thead>
						<tbody>
							{pemasukan.map((item) => (
								<tr
									key={item._id}
									className="hover:bg-gray-50"
								>
									<td className="py-2 px-4 border-b">{item.Keterangan}</td>
									<td className="py-2 px-4 border-b">
										Rp {item.Jumlah.toLocaleString()}
									</td>
									<td className="py-2 px-4 border-b">
										{new Date(item.Tanggal).toLocaleDateString()}
									</td>
									<td className="py-2 px-4 border-b">
										<button
											onClick={() => openModal({ ...item, id: item._id })}
											className="text-blue-500 mr-2"
										>
											Edit
										</button>
										<button
											onClick={() => deleteKeuangan(item._id)}
											className="text-red-500"
										>
											Hapus
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Card Pengeluaran */}
			<div className="w-full md:w-1/2 p-2">
				<div className="bg-white shadow-lg rounded-lg p-6">
					<h2 className="text-xl font-semibold mb-4">
						Jumlah Pengeluaran Diluar Pesanan
					</h2>
					<p className="text-2xl text-red-500 mb-4">
						Rp {totalPengeluaran.toLocaleString()}
					</p>
					<button
						onClick={() =>
							openModal({
								Keterangan: "",
								Jumlah: 0,
								Tanggal: "",
								Jenis: "Pengeluaran",
								PenanggungJawab: "",
							})
						}
						className="bg-red-500 text-white p-1 rounded mb-4"
					>
						Tambah
					</button>
					<table className="min-w-full bg-white border border-gray-200">
						<thead>
							<tr className="bg-gray-100">
								<th className="py-2 px-4 border-b text-left">Keterangan</th>
								<th className="py-2 px-4 border-b text-left">
									Jumlah Pengeluaran
								</th>
								<th className="py-2 px-4 border-b text-left">Tanggal</th>
								<th className="py-2 px-4 border-b text-left">
									Penanggung Jawab
								</th>
								<th className="py-2 px-4 border-b text-left">Aksi</th>
							</tr>
						</thead>
						<tbody>
							{pengeluaran.map((item) => (
								<tr
									key={item._id}
									className="hover:bg-gray-50"
								>
									<td className="py-2 px-4 border-b">{item.Keterangan}</td>
									<td className="py-2 px-4 border-b">
										Rp {item.Jumlah.toLocaleString()}
									</td>
									<td className="py-2 px-4 border-b">
										{new Date(item.Tanggal).toLocaleDateString()}
									</td>
									<td className="py-2 px-4 border-b">{item.PenanggungJawab}</td>
									<td className="py-2 px-4 border-b">
										<button
											onClick={() => openModal({ ...item, id: item._id })}
											className="text-blue-500 mr-2"
										>
											Edit
										</button>
										<button
											onClick={() => deleteKeuangan(item._id)}
											className="text-red-500"
										>
											Hapus
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Download Button */}
			{/* <div className="w-full p-2">
				<button
					onClick={downloadData}
					className="bg-blue-500 text-white p-2 rounded"
				>
					Download Data
				</button>
			</div> */}

			{/* Modal */}
			{isModalOpen && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
					<div className="bg-white p-6 rounded-lg">
						<h2 className="text-xl font-semibold mb-4">Edit Data</h2>
						<input
							type="text"
							placeholder="Keterangan"
							value={editData.Keterangan}
							onChange={(e) =>
								setEditData({ ...editData, Keterangan: e.target.value })
							}
							className="border p-1 mr-2"
						/>
						<input
							type="number"
							placeholder="Jumlah"
							value={editData.Jumlah}
							onChange={(e) =>
								setEditData({
									...editData,
									Jumlah: Number(e.target.value),
								})
							}
							className="border p-1 mr-2"
						/>
						<input
							type="date"
							value={editData.Tanggal}
							onChange={(e) =>
								setEditData({ ...editData, Tanggal: e.target.value })
							}
							className="border p-1 mr-2"
						/>
						{editData.Jenis === "Pengeluaran" && (
							<input
								type="text"
								placeholder="Penanggung Jawab"
								value={editData.PenanggungJawab}
								onChange={(e) =>
									setEditData({ ...editData, PenanggungJawab: e.target.value })
								}
								className="border p-1 mr-2"
							/>
						)}
						<div className="mt-4">
							<button
								onClick={addOrEditData}
								className="bg-green-500 text-white p-1 rounded mr-2"
							>
								Simpan
							</button>
							<button
								onClick={closeModal}
								className="bg-gray-500 text-white p-1 rounded"
							>
								Batal
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DataKeuangan;
