import React from "react";

function Modal({ show, onClose, children }) {
	if (!show) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
			<div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-full overflow-y-auto">
				{children}
				<button
					className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
					onClick={onClose}
				>
					Close
				</button>
			</div>
		</div>
	);
}

export default Modal;
