import React, { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div
			className={`bg-gray-100 p-5 flex-shrink-0 transition-all duration-300 ease-in-out ${
				isOpen ? "w-64" : "w-16"
			}`}
			onClick={toggleSidebar}
		>
			<div className="flex flex-col items-center">
				<div className="cursor-pointer mb-10">
					<img
						src="src/components/Admin/Logo.png"
						alt="Logo"
						className="w-8 h-8"
					/>
				</div>
				<ul className="space-y-4">
					<li className="flex items-center text-gray-800">
						<span className="text-lg">🏠</span>
						{isOpen && (
							<Link
								to="/admin"
								className="ml-4"
							>
								Dashboard
							</Link>
						)}
					</li>
					<li className="flex items-center text-gray-600">
						<span className="text-lg">⚙️</span>
						{isOpen && (
							<Link
								to="/store-settings"
								className="ml-4"
							>
								Pengaturan Toko
							</Link>
						)}
					</li>
					<li className="flex items-center text-gray-600">
						<span className="text-lg">📦</span>
						{isOpen && (
							<Link
								to="/data-products"
								className="ml-4"
							>
								Data Product
							</Link>
						)}
					</li>
					<li className="flex items-center text-gray-600">
						<span className="text-lg">🛒</span>{" "}
						{/* Shopping cart icon for sales */}
						{isOpen && (
							<Link
								to="/sales"
								className="ml-4"
							>
								Penjualan
							</Link>
						)}
					</li>
					<li className="flex items-center text-gray-600">
						<span className="text-lg">💰</span>{" "}
						{/* Money bag icon for finance */}
						{isOpen && (
							<Link
								to="/finance"
								className="ml-4"
							>
								Keuangan
							</Link>
						)}
					</li>

					<li className="flex items-center text-gray-600">
						<span className="text-lg">📩</span>
						{isOpen && (
							<Link
								to="/Pesan"
								className="ml-4 flex items-center"
							>
								Messages
							</Link>
						)}
					</li>
					<li className="flex items-center text-gray-600">
						<span className="text-lg">⚙️</span>
						{isOpen && (
							<Link
								to="/settings"
								className="ml-4"
							>
								Settings
							</Link>
						)}
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Sidebar;
