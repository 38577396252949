import React, { useEffect, useState } from "react";
import Modal from "./Modal";

function PriceIncreaseModal({
	isCardModalOpen,
	setIsCardModalOpen,
	selectedStore,
	priceIncreases,
	setPriceIncreases,
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchPriceData = async () => {
			const user = localStorage.getItem("id");
			if (!user) {
				setError("User ID not found");
				return;
			}

			const token = localStorage.getItem("token");
			const url = `${process.env.REACT_APP_API_BASE_URL}/users/${user}/prices`;

			setLoading(true);
			try {
				const response = await fetch(url, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const data = await response.json();
				setPriceIncreases(data);
				setError(null);
				console.log("API Response:", data);
			} catch (error) {
				setError(`Failed to fetch price data: ${error.message}`);
				console.error("Fetch error:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPriceData();
	}, [setPriceIncreases]);

	const handlePriceIncreaseChange = (range, field, value) => {
		setPriceIncreases((prev) => ({
			...prev,
			[range]: {
				...prev[range],
				[field]: field === "value" ? parseInt(value) : value,
			},
		}));
	};

	const applyPriceIncreases = async () => {
		try {
			const token = localStorage.getItem("token");
			const id = localStorage.getItem("id");

			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/updateharga/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(priceIncreases),
				}
			);

			if (!response.ok) {
				throw new Error("Failed to update price increases");
			}

			alert("Price increases saved successfully!");
		} catch (error) {
			console.error("Error saving price increases:", error);
			alert("There was an error saving the price increases.");
		}
	};

	return (
		<Modal
			show={isCardModalOpen}
			onClose={() => setIsCardModalOpen(false)}
		>
			<h2 className="text-lg font-bold mb-4 text-center">
				{selectedStore ? selectedStore.Nama_Toko : "Select a Store"}
			</h2>
			{loading ? (
				<p>Loading...</p>
			) : error ? (
				<p className="text-red-500">{error}</p>
			) : (
				<div>
					<h3 className="font-semibold mb-2">Setting Kenaikan Harga</h3>
					<div className="flex flex-col gap-2">
						{Object.keys(priceIncreases).map((range) => (
							<div
								key={range}
								className="flex items-center"
							>
								<input
									type="number"
									placeholder={`Increase for ${range.replace(
										/(\d)(?=(\d{3})+(?!\d))/g,
										"$1."
									)}`}
									className="p-2 border rounded flex-1"
									value={priceIncreases[range].value || ""}
									onChange={(e) =>
										handlePriceIncreaseChange(range, "value", e.target.value)
									}
								/>
								<label className="ml-2 flex items-center">
									<input
										type="checkbox"
										checked={priceIncreases[range].usePercentage || false}
										onChange={(e) =>
											handlePriceIncreaseChange(
												range,
												"usePercentage",
												e.target.checked
											)
										}
									/>
									<span className="ml-1">%</span>
								</label>
							</div>
						))}
					</div>
					<button
						className="mt-4 bg-blue-500 text-white px-4 py-2 rounded w-full"
						onClick={applyPriceIncreases}
					>
						Save
					</button>
				</div>
			)}
		</Modal>
	);
}

export default PriceIncreaseModal;
