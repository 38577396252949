import React from "react";
import Sidebar from "../components/Admin/Sidebar";
import Pesan from "../components/Admin/Admin-Pesan/Pesan";

function Admin() {
	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-1 bg-gray-50">
				<Pesan />
			</div>
		</div>
	);
}

export default Admin;
