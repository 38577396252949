import React from "react";

function ProductTable({ products, showModal, deleteProduct }) {
	return (
		<div className="overflow-x-auto mt-6">
			<table className="table-auto w-full bg-white shadow rounded-lg">
				<thead>
					<tr>
						<th className="px-4 py-2">No</th>
						<th className="px-4 py-2">Nama Product</th>
						<th className="px-4 py-2">ID Product</th>
						<th className="px-4 py-2">Tanggal Update</th>
						<th className="px-4 py-2">Tanggal Scrape</th>
						<th className="px-4 py-2">Action</th>
					</tr>
				</thead>
				<tbody>
					{products.map((product, index) => (
						<tr key={product._id}>
							<td className="border px-4 py-2">{index + 1}</td>
							<td className="border px-4 py-2">
								{product.Nama_Product_Shopee}
							</td>
							<td className="border px-4 py-2">{product.Id_product}</td>
							<td className="border px-4 py-2">
								{new Date(product.Tanggal_Update).toLocaleString()}
							</td>
							<td className="border px-4 py-2">
								{new Date(product.Tanggal_Scrape).toLocaleString()}
							</td>
							<td className="border px-4 py-2">
								<button
									className="text-blue-500 mr-2"
									onClick={() => showModal(product)}
								>
									Detail
								</button>
								<button
									className="text-red-500"
									onClick={() => deleteProduct(product._id)}
								>
									Delete
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default ProductTable;
