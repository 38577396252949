import React from "react";
import { useNavigate } from "react-router-dom";
import KOL from "../../asset/KOL.png";
import mentor from "../../asset/mentor.png";
import influencer from "../../asset/influencer.png";
import affiliate from "../../asset/Affiliate.png";
import kelas from "../../asset/kelas.png";
import ERP from "../../asset/ERP.png";
import LP from "../../asset/LP.png";
import Analisa from "../../asset/Analisa.png";

// Array untuk kategori
const categories = [
	{ name: "KOL Specialis", image: KOL },
	{ name: "Influencer", image: influencer },
	{ name: "Mentor Bisnis", image: mentor },
	{ name: "Affiliate", image: affiliate },
];

// Array untuk semua produk
const allProducts = [
	{
		name: "Kelas Dasar Bisnis",
		image: kelas,
		description: `Dapatkan materi dasar terkait bisnis yang akan anda jalankan secara gratis.`,
	},
	{
		name: "ERP Management",
		image: ERP,
		description: `Dapatakan ERP program secara gratis.`,
	},
	{
		name: "Landing Page Build",
		image: LP,
		description: `Buat Landingpage brandmu secara gratis.`,
	},
	{
		name: "Analisa Product",
		image: Analisa,
		description: `Tidur lebih nyaman dan nyenyak dengan Baju Tidur wanita kami yang lembut dan breathable. Terbuat dari bahan yang ringan dan halus di kulit, memastikan kenyamanan tidur Anda.`,
	},
];

const App = () => {
	const navigate = useNavigate();

	return (
		<>
			{/* Bagian Kategori */}
			<div className="max-w-6xl mx-auto p-5">
				<h1 className="text-3xl font-bold mb-8 text-left text-gray-800 mt-20">
					Lini Glow Up Fitur
				</h1>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
					{categories.map((category, index) => (
						<div
							className="border p-4 bg-white shadow-md hover:shadow-lg transition"
							key={index}
						>
							<div
								onClick={() => navigate("/allert")}
								className="cursor-pointer"
							>
								<img
									src={category.image}
									alt={`${category.name} Image`}
									className="w-full h-40 object-contain mb-4"
								/>
								<h3 className="text-lg font-semibold">{category.name}</h3>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Bagian Semua Produk */}
			<div className="max-w-6xl mx-auto p-5">
				<h1 className="text-3xl font-bold mb-8 text-left text-gray-800">
					Semua Fitur
				</h1>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
					{allProducts.map((product, index) => (
						<div
							className="border p-4 bg-white shadow-md hover:shadow-lg transition cursor-pointer"
							key={index}
							onClick={() => navigate("/allert")}
						>
							<div className="h-40 mb-4">
								<img
									src={product.image}
									alt={`${product.name} Image`}
									className="w-full h-full object-contain"
								/>
							</div>
							<h3 className="text-lg font-semibold">{product.name}</h3>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default App;
