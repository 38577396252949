import React from "react";
import { Link } from "react-router-dom";

function WarningPage() {
	return (
		<div className="flex justify-center items-center h-screen bg-gray-100 p-5">
			<div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-sm w-full">
				<h2 className="text-red-600 text-2xl font-bold mb-5">Peringatan!</h2>
				<p className="text-lg text-gray-800 mb-8">
					Halaman ini tersedia untuk mitra saja, silahkan kembali.
				</p>
				<Link
					to="/"
					className="inline-block bg-indigo-600 text-white py-3 px-8 rounded-lg font-semibold transition-transform hover:bg-indigo-500 hover:shadow-lg hover:-translate-y-1"
				>
					Kembali
				</Link>
			</div>
		</div>
	);
}

export default WarningPage;
