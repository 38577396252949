import { useEffect, useState } from "react";
import axios from "axios";

const useTitleUpdater = () => {
	const [orders, setOrders] = useState(0);
	const [stores, setStores] = useState([]);

	const fetchOrders = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.get(
				`${process.env.REACT_APP_API_BASE_URL}/order`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// Calculate total "perlu dikirim (belum diproses)" orders
			const totalNewOrders = response.data.reduce((acc, store) => {
				return acc + (store.orders["perlu dikirim (belum diproses)"] || 0);
			}, 0);

			setOrders(totalNewOrders);
		} catch (error) {
			console.error("Error fetching orders:", error);
			setOrders(0); // Ensure orders is set to 0 on error
		}
	};

	const fetchStores = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/toko`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			const formattedStores = await Promise.all(
				data.map(async (store) => {
					const { unreadCount } = await fetchUnreadMessages(store._id);
					return {
						...store,
						unreadCount,
					};
				})
			);
			setStores(formattedStores);
		} catch (error) {
			console.error("Failed to fetch stores:", error);
			setStores([]);
		}
	};

	const fetchUnreadMessages = async (storeId) => {
		try {
			const token = localStorage.getItem("token");
			const response = await fetch(
				`${process.env.REACT_APP_API_BASE_URL}/chatunread/${storeId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (!response.ok) {
				return { unreadCount: 0 };
			}

			const data = await response.json();
			return { unreadCount: data.total_unread_conv_count };
		} catch (error) {
			console.error("Error fetching unread messages:", error);
			return { unreadCount: 0 };
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			fetchOrders();
			fetchStores();
		}, 120000); // Fetch every 2 minutes

		return () => clearInterval(interval); // Cleanup on component unmount
	}, []);

	useEffect(() => {
		const unreadMessages = stores.reduce(
			(acc, store) => acc + store.unreadCount,
			0
		);
		document.title = `${unreadMessages} pesan - ${orders} pesanan baru`;
	}, [stores, orders]);
};

export default useTitleUpdater;
